import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { SpinnerSize, Spinner } from '@fluentui/react';
import * as serviceWorker from './serviceWorker';

import { initializeTheme } from './theme';
import AuthProvider from './providers/AuthProvider';
import ErrorBoundary from './components/utils/ErrorBoundary';
import Main from './components/apps/Main';
import Maintenance from "./components/common/Maintenance";
import { UrlListener } from "./components/utils/UrlListener";
import './index.css';

initializeTheme();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary errorComponent={Maintenance}>
      <Router>
        <UrlListener />
        <RecoilRoot>
          <AuthProvider>
            <Suspense fallback={<div className='da-general-loading'>
              <Spinner size={SpinnerSize.large} />
            </div>}>
                <Switch>
                  <Route path={'/'} component={Main} />
                  <Redirect to={'/'} />
                </Switch>
            </Suspense>
          </AuthProvider>
        </RecoilRoot>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
