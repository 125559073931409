import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { dwaDatasets } from 'recoil/atoms/dwaDatasets';
import { dwaSelectedDatasetsNames } from 'recoil/selectors/dwaSelectedDatasetsNames';

import { IDWAConfigDataset } from 'types/IDWA';

import { getStatusCount, sortDataset } from 'utils/datasetHelper';

export const useLocalUpdateDatasets = () => {

	const selectedDatasetsNames = useRecoilValue(dwaSelectedDatasetsNames);
	const [datasets, setDatasets] = useRecoilState(dwaDatasets);

	const updateDatasets = useCallback((updatedValue: Partial<IDWAConfigDataset>) => {
		if (selectedDatasetsNames.length > 0) {

			const data = datasets.data.map(dataset => {
				if (selectedDatasetsNames.includes(dataset.name) && dataset.status !== 'removed') {
					return {
						...dataset,
						...updatedValue,
						modified: true
					};
				}
				return dataset;
			});

			const {
				managed: managedCount, 
				unmanaged: unmanagedCount, 
				removed: removedCount
			} = getStatusCount(data);

			setDatasets(state => ({
				...state,
				data: sortDataset(data),
				managedCount,
				unmanagedCount,
				removedCount,
			}));
		}
	}, [selectedDatasetsNames, datasets, setDatasets]);

	return updateDatasets;
};