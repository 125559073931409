import { atomFamily, SerializableParam, selector, waitForAll } from 'recoil';

import { dwaSelectedDataset } from 'recoil/atoms/dwaSelectedDataset';
import { selectedEnvironmentId } from 'recoil/atoms/selectedEnvironmentId';
import { selectedSolutionId } from 'recoil/atoms/selectedSolutionId';

import { fetchRemoteEffect } from 'recoil/effects/fetchRemoteEffect';

import { apiEndpoint } from 'recoil/remote/apiEndpoint';
import { dwa } from "recoil/remote/dwa";
import { noopPromise } from 'recoil/remote/noopPromise';

import { IWatermarks } from 'types/IWatermarks';

import { standardHeaders } from "./standardHeaders";

export type IWatermarksParams = SerializableParam & {
	endpoint: string;
	solutionId: string;
	environmentId: string;
	datasourceId: string;
	datasetId: string;
}

export const watermarksAtom = atomFamily<IWatermarks[] | null, IWatermarksParams>({
	key: 'watermarks',
	default: noopPromise(null),
	effects_UNSTABLE:[
		fetchRemoteEffect<IWatermarksParams>(
			({ endpoint, solutionId, environmentId, datasourceId, datasetId }) => 
				`${endpoint}/${solutionId}/environments/${environmentId}/datasources/${datasourceId}/datasets/${datasetId}/watermark`
		)
	]
})

export const watermarks = selector<IWatermarks[] | null>({
	key: 'watermarks/proxy',
	get: async ({ get }) => {
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		const dwaData = get(dwa);;
		const dataset = get(dwaSelectedDataset);
		
		if (endpoint && environmentId && dwaData?.de_config.datasource_id && dataset?.dataset_id) {
			const result = get(waitForAll([
				watermarksAtom({ endpoint, solutionId, environmentId, datasourceId: dwaData.de_config.datasource_id, datasetId: dataset.dataset_id })
			]))[0];
			return [...result ?? []].reverse();
		}

		return null;
	}
});

export const createWatermark = async (apiEndpoint: string, solutionId: string, environmentId: string, datasourceId: string, datasetId: string, watermark?: string) => {
	const response = await fetch(`${apiEndpoint}/${solutionId}/environments/${environmentId}/datasources/${datasourceId}/datasets/${datasetId}/create_watermark`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify({ type: 'watermark_end', watermark })
	});
	
	return response;
}
