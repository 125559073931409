import { atomFamily, selector, SerializableParam } from 'recoil';
import { IEnvironment } from '../../types/IEnvironment';
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { noopPromise } from "./noopPromise";

export type IEnvironmentParam = SerializableParam & {
	endpoint: string;
	solutionId: string;
	environmentId: string;
}

export const environmentAtom = atomFamily<IEnvironment | null, IEnvironmentParam>({
	key: 'environment',
	default: noopPromise(null),
	effects_UNSTABLE:[
		fetchRemoteEffect<IEnvironmentParam>(({ endpoint, solutionId, environmentId }) => 
			`${endpoint}/${solutionId}/environments/${environmentId}`)
	]
});

export const environment = selector<IEnvironment | null>({
	key: 'environment/proxy',
	get: async({ get }) => {
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);

		if (endpoint && environmentId) {
			return get(environmentAtom({ endpoint, solutionId, environmentId }));
		}

		return null;
	}
});
