import React from 'react';
import { useRecoilState } from 'recoil';
import { IconButton, IContextualMenuItem } from '@fluentui/react';

import { dwaDatasetFilters } from 'recoil/atoms/dwaDatasetFilters';

import { getSelectedTypeValues } from 'components/utils/DWAS2MHelper'

import { filterOptions } from 'enums/dwaFilterOptions';

import './DWAS2MFilterButton.css';

export const DWAS2MFilterButton: React.FC = () => {
	const [filters, setFilters] = useRecoilState(dwaDatasetFilters);

	const {showModified, selectedTypes} = filters;
	
	const showByStatus = (key: string | undefined) => {
		switch(key) {
			case 'showmodified':
			  	return true
			case 'showunmodified':
				return false
			default:
				return undefined;
		}
	}

	const filterByType = (key: string | undefined) => {
		if (key === undefined) {
			return key;
		};

		const selectedTypeValues = getSelectedTypeValues(key)
	
		return selectedTypeValues || undefined;
	}
	
	const filterButtonOptions = () => ({
		className:'da-filter-dataset',
		directionalHintFixed: true,
		items: [
			{
				key: 'showall',
				text: 'Show All',
			},
			...filterOptions
		],
		onItemClick: (_: any, item?: IContextualMenuItem) => {
			const { key } = item || {};

			setFilters(filters => ({
				...filters,
				selectedTypes: filterByType(key),
				showModified: showByStatus(key),
			}));
		},
	});

	const buttonIcon = { iconName: ([showModified, selectedTypes]
		.some(option => option)) ? 'FilterSolid' : 'Filter' };

	const buttonTitle = 'Filter';

	return (
		<IconButton
			ariaLabel={buttonTitle}
			menuProps={filterButtonOptions()}
			iconProps={buttonIcon}
			title={buttonTitle}
		/>
	)
};
