import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { dwaDatasets } from 'recoil/atoms/dwaDatasets';

import { IDWAConfigDataset } from 'types/IDWA';

import { getStatusCount, sortDataset } from 'utils/datasetHelper';

export const useLocalUpdateDataset = () => {
	const [datasets, setDatasets] = useRecoilState(dwaDatasets);

	const updateDataset = useCallback((datasetName: string, updatedValue: Partial<IDWAConfigDataset>) => {
		let index = 0;
		const dataset = datasets.data.find((dataset, i) => {
			if (dataset.name === datasetName) {
				index = i;
				return true;
			}
			return false;
		});

		const preItems = datasets.data.slice(0, index!);
		const postItems = datasets.data.slice(index! + 1);

		if (dataset && dataset.status !== 'removed') {
			const data = [
				...preItems,
				{
					...dataset,
					...updatedValue,
					modified: true
				},
				...postItems
			];

			const {
				managed: managedCount, 
				unmanaged: unmanagedCount, 
				removed: removedCount
			} = getStatusCount(data);

			setDatasets(state => ({
				...state,
				data: sortDataset(data),
				managedCount,
				unmanagedCount,
				removedCount,
			}));
		}
	}, [datasets, setDatasets]);

	return updateDataset;
};