import { useCallback, useEffect } from "react";
import { useSetRecoilState, useRecoilValue, useResetRecoilState, useRecoilValueLoadable, useRecoilState } from "recoil";
import { deployStatusAtom } from "../recoil/atoms/dwaDeployStatus";
import { dwaActiveTab } from "../recoil/selectors/dwaActiveTab";
import { publishDwa } from "../recoil/remote/publishDwa";
import { dwaConfigSettings } from "../recoil/atoms/dwaConfigSettings";
import { dwa, dwaAtom } from "../recoil/remote/dwa";
import { dwaDatasets } from "../recoil/atoms/dwaDatasets";
import { IDWAConfigDataset } from "../types/IDWA";
import { useSelectedParams } from './useSelectedParams';
import { jobsAtom } from "../recoil/remote/jobs";
import { JOB_IN_PROGRESS, JOB_NOT_STARTED } from "../constants/jobStatus";
import { DEPLOY_IDLE, DEPLOY_IN_PROGRESS, DEPLOY_JUST_NOW, DEPLOY_UNKNOWN } from "../constants/deployStatus";

export const usePublishDWA = () => {
  const {endpoint, solutionId, environmentId, dwaId} = useSelectedParams();
  const [deployStatus, setDeployStatus] = useRecoilState(deployStatusAtom({ dwaId }));
  const dwaData       = useRecoilValue(dwa);
  const datasets      = useRecoilValue(dwaDatasets);
  const alteredConfig = useRecoilValue(dwaConfigSettings);
  const setActiveTab  = useSetRecoilState(dwaActiveTab);
  const jobs          = jobsAtom({endpoint, solutionId, environmentId, dwaId});
  const remoteJobs    = useRecoilValueLoadable(jobs);
  const refreshJobs   = useResetRecoilState(jobs);
  const refreshDwa    = useResetRecoilState(dwaAtom({endpoint, solutionId, environmentId, dwaId}));

  useEffect(() => {

    const pendingJob = remoteJobs.state === 'hasValue' ? remoteJobs.contents.find(job => {
      return job.statusText === JOB_NOT_STARTED || job.statusText === JOB_IN_PROGRESS;
    }) : null;
    
    // null means not sure, so do nothing
    // undefined means idle
    // value means in progress

    if (deployStatus === DEPLOY_UNKNOWN) {
      if (remoteJobs.state === 'hasValue') {
        if (typeof pendingJob === 'undefined') {
          setDeployStatus(DEPLOY_IDLE);
        } else {
          setDeployStatus(DEPLOY_IN_PROGRESS);
        }
      }
    } else if (deployStatus === DEPLOY_IN_PROGRESS) {
      if (typeof pendingJob === 'undefined') {
        setDeployStatus(DEPLOY_IDLE);
      }
    } else {
      if (pendingJob) {
        setDeployStatus(DEPLOY_IN_PROGRESS);
      }
    }

  }, [remoteJobs, deployStatus, setDeployStatus]);

  const refresh = useCallback(() => {
    refreshJobs();
    refreshDwa();
  }, [refreshJobs, refreshDwa]);

  return useCallback(
    async (dwaJobType: string) => {
      if (dwaData) {
        setDeployStatus(DEPLOY_JUST_NOW);
        setActiveTab("logs");

        const newConfig = JSON.stringify({
          ...alteredConfig,
          datasource_id:
            dwaData.de_config.datasource_id,
          datasets: datasets.data.reduce<IDWAConfigDataset[]>(
            (newDatasets, dataset) => {
              const columns = dataset.columns.filter(
                (column) => column.status?.toLowerCase() !== "removed"
              ).map((x) => {
                const {status, ...rest} = x;
                return rest;
               });
               
              const { status, modified, ...newDataset } = dataset;

              if (
                columns.length > 0 &&
                dataset.status?.toLowerCase() !== "removed" &&
                !(
                  dataset.status?.toLowerCase() === "unmanaged" &&
                  !dataset.modified
                )
              ) {
                return [
                  ...newDatasets,
                  {
                    ...newDataset,
                    columns,
                  },
                ];
              }

              return newDatasets;
            },
            []
          ),
        });

        const response = await publishDwa(
          endpoint,
          solutionId,
          environmentId,
          dwaId,
          newConfig,
          dwaJobType
        );
        setDeployStatus(DEPLOY_UNKNOWN);
        if (response.ok) {
          refresh();
        }
      }
    },
    [
      dwaData,
      datasets.data,
      alteredConfig,
      refresh,
      setDeployStatus,
      setActiveTab,
      solutionId,
      environmentId,
      dwaId,
      endpoint
    ]
  );
};
