import React from "react";
import { useRecoilState } from "recoil";
import { Pivot, PivotItem } from "@fluentui/react";
import { dwaActiveTab } from "../../../../../recoil/selectors/dwaActiveTab";
import "./DWAStandUpTabs.css";

export const DWAStandUpTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useRecoilState(dwaActiveTab);
  return (
    <div className="da-panel-tabs">
      <Pivot
        linkSize={'large'}
        headersOnly={true}
        selectedKey={activeTab}
        onLinkClick={(item) => setActiveTab(item!.props.itemKey!)}
      >
        <PivotItem headerText="General Settings" itemKey="settings" />
        <PivotItem headerText={`Logs`} itemKey="logs" />
      </Pivot>
    </div>
  );
};
