import { IDropdownOption } from "@fluentui/react";

export const s2mSQLSourceDatasetTypeOptions: IDropdownOption[] = [
  { key: "SQLSERVER", text: "SQL Server" },
  { key: "AZURESQL", text: "Azure SQL" },
  { key: "ODBC", text: "ODBC" },
  { key: "LINKEDSQLSERVER", text: "Linked SQL Server" },
  { key: "POSTGRESQL", text: "PostgreSQL" },
  { key: "ORACLE", text:"Oracle"},
];
