const statusOptions = [
    {
        key: 'showmodified',
        text: 'Show Modified',
    },
    {
        key: 'showunmodified',
        text: 'Show Unmodified',
    },
];

const typeOptions = [
    {
        key: 'fulladdupdatedelete', 
        text:'Show Full - Add, Update, Delete',
    },
    {
        key: 'deltaaddupdatedelete', 
        text:'Show Delta - Add, Update, Delete',
    },
    {
        key: 'fulladdupdate', 
        text:'Show Full - Add, Update',
    },
    {
        key: 'deltaaddupdate', 
        text:'Show Delta - Add, Update',
    },
    {
        key: 'fullappend', 
        text:'Show Full - Append',
    },
    {
        key: 'deltaappend', 
        text:'Show Delta - Append',
    },
];

export const filterOptions = [
    ...statusOptions,
    ...typeOptions
]