import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue, useRecoilValueLoadable, useResetRecoilState } from "recoil";
import {
  DetailsList,
  SelectionMode,
  SpinnerSize,
  Spinner,
  Dropdown,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { useHistory, useParams } from "react-router";
import { IJob } from "../../../../../../types/IJob";
import { IDWAJobsFilters } from "../../../../../../types/IDWAJobsFilters";
import { useSelectedParams } from "../../../../../../hooks/useSelectedParams";
import { dwaActiveTab } from "../../../../../../recoil/selectors/dwaActiveTab";
import { job } from "../../../../../../recoil/remote/job";
import { jobs, jobsAtom } from "../../../../../../recoil/remote/jobs";
import { deployStatusAtom } from "../../../../../../recoil/atoms/dwaDeployStatus";
import { JOB_NOT_STARTED } from "../../../../../../constants/jobStatus";
import { jobLogsColumnFields } from "../../../../../../enums/jobLogsColumnFields";
import { Spacer } from "../../../../../common/Spacer";
import { DEPLOY_IDLE, DEPLOY_IN_PROGRESS, DEPLOY_JUST_NOW, DEPLOY_UNKNOWN } from "../../../../../../constants/deployStatus";
import { JobLogsPanel } from "./JobLogsPanel";
import "./JobsTab.css";

export const limitOptions = [
  { key: -1, text: "Show All" },
  { key: 5, text: "Show recent (5)" },
  { key: 10, text: "Show recent (10)" },
  { key: 20, text: "Show recent (20)" },
];

export const JobsTab: React.FC = () => {

  const { endpoint, solutionId, environmentId, dwaId } = useSelectedParams();
  const [cachedData, setCachedData] = useState<IJob[] | null>(null);
  const [filters, setFilters]       = useState<IDWAJobsFilters>({ limit: 5 });
  const [headerText, setHeaderText] = useState('');
  const { jobId }    = useParams<any>();
  const history      = useHistory();
  const lastState    = useRef('');
  const prevSelected = useRef('');
	const activeTab    = useRecoilValue(dwaActiveTab);
  const selectedJob  = useRecoilValueLoadable(job);
	const remoteData   = useRecoilValueLoadable(jobs);
	const refreshJobs  = useResetRecoilState(jobsAtom({ endpoint, solutionId, environmentId, dwaId }));
  const deployStatus = useRecoilValue(deployStatusAtom({ dwaId }));
  const filteredData = useMemo<IJob[] | null>(() => {
    if (!cachedData) {
      return null;
    }
    if (filters.limit === -1) {
      return cachedData;
    }
    return cachedData.slice(0, filters.limit + 1);
  }, [filters, cachedData]);

  useEffect(() => {
		let count = 0;
		const pollId = setInterval(() => {
			count++;
			if (count >= 10 || (activeTab === 'logs' && count >= 5)) {
				refreshJobs();
				count = 0;
			}
		}, 1000);
		return () => { 
			clearInterval(pollId);
		};
	}, [refreshJobs, activeTab]);

  useEffect(() => {
    if (lastState.current !== 'hasValue') {
      if (remoteData.state === 'hasValue') {
        setCachedData(remoteData.contents);
      }
    }
    lastState.current = remoteData.state;
  }, [remoteData, setCachedData]);

  useEffect(() => {
    if (selectedJob.state === 'hasValue' && selectedJob.contents) {
      setHeaderText(`${selectedJob.contents.id} (${selectedJob.contents.statusText})`);
    }
  }, [setHeaderText, selectedJob]);

  const onItemInvoked = (item: IJob) => {
    if (item.statusText !== JOB_NOT_STARTED) {
      prevSelected.current = item.id;
      history.push(`/solution/${solutionId}/environment/${environmentId}/dwa/${dwaId}/jobs/${item.id}`);
    }
  };

  const onActiveItemChanged = (item: IJob) => {
    if (prevSelected.current !== item.id) {
      onItemInvoked(item);
    }
  };

  const spinnerLabel = deployStatus === DEPLOY_JUST_NOW ? 'Publishing...' : (
    deployStatus === DEPLOY_IN_PROGRESS ? 
      'Waiting for job to complete...' : (
        deployStatus === DEPLOY_UNKNOWN ?  'Loading...' : ''
      )
    );

  return (
    <div className="da-jobs">
      <div className="da-jobs-options">
        {(deployStatus !== DEPLOY_IDLE) && (
          <Spinner
            size={SpinnerSize.large}
            labelPosition="right"
            label={spinnerLabel}
          />
        )}
        <Spacer />
        <Dropdown
          selectedKey={filters.limit}
          options={limitOptions}
          styles={{ dropdown: { width: 150 } }}
          onChange={(_: any, newValue: any) =>
            setFilters((state) => ({ ...state, limit: +newValue.key }))
          }
          disabled={cachedData?.length === 0}
        />
      </div>
      {filteredData && <DetailsList
        items={filteredData}
        columns={jobLogsColumnFields}
        selectionMode={SelectionMode.none}
        onActiveItemChanged={onActiveItemChanged}
        onItemInvoked={onItemInvoked}
      />}
      <Panel
        type={PanelType.medium}
        isOpen={!!jobId}
        onDismiss={() => history.push(`/solution/${solutionId}/environment/${environmentId}/dwa/${dwaId}`)}
        isLightDismiss={true}
        headerText={headerText}
      >
        <Pivot selectedKey={"logs"}>
          <PivotItem headerText="Logs" itemKey="logs">
            <JobLogsPanel key={headerText} />
          </PivotItem>
        </Pivot>
      </Panel>
    </div>
  );
};
