import React, { useState, useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  Dropdown,
  TextField,
  Breadcrumb,
  CommandButton,
} from "@fluentui/react";
import { IDWAStandUpErrorFields } from "../../../../../types/IDWAStandUpErrorFields";
import { usePublishDWA } from "../../../../../hooks/usePublishDWA";
import { regionOptions } from "../../../../../enums/regionOptions";
import { breadcrumbsAtom } from "../../../../../recoil/atoms/breadcrumbs";
import { dwaActiveTab } from "../../../../../recoil/selectors/dwaActiveTab";
import { dwa } from "../../../../../recoil/remote/dwa";
import { dwaConfigSettings } from "../../../../../recoil/atoms/dwaConfigSettings";
import { CommandBar } from "../../../../common/CommandBar";
import { Spacer } from "../../../../common/Spacer";
import { Container } from "../../../../common/Container";
import { DWAStandUpTabs } from "./DWAStandUpTabs";
import { JobsTab } from "../common/jobs/JobsTab";
import "../DWAS2M.css";

export const DWAStandUpPage: React.FC = () => {
	const dwaData = useRecoilValue(dwa);
  const [config, setConfig] = useRecoilState(dwaConfigSettings);

  const breadcrumbs = useRecoilValue(breadcrumbsAtom);
  const [errors, setErrors] = useState<Partial<IDWAStandUpErrorFields>>({});
  const [isDeploying, setIsDeploying] = useState(false);
  const submitJob = usePublishDWA();
  const activeTab = useRecoilValue(dwaActiveTab);

  const validate = useCallback(() => {
    const errors: Partial<IDWAStandUpErrorFields> = {};

    if (!config.subscription_id) {
      errors.subscription_id = "Invalid Subscription ID";
    }

    if (!config.rg_name) {
      errors.rg_name = "Invalid Resource Group";
    }

    if (!config.keyvault_name) {
      errors.keyvault_name = "Invalid Keyvault Name";
    }

    if (!config.storage_name) {
      errors.storage_name = "Invalid Storage Name";
    }

    if (!config.datafactory_name) {
      errors.datafactory_name = "Invalid Datafactory Name";
    }

    if (!config.databricks_name) {
      errors.databricks_name = "Invalid Databricks Name";
    }

    if (!config.databricks_managed_resource_group) {
      errors.databricks_managed_resource_group = "Invalid Databricks Token";
    }

    if (!config.tenant_id) {
      errors.tenant_id = "Invalid Tenant ID";
    }

    if (!config.kv_default_admin_object_id) {
      errors.kv_default_admin_object_id = "Invalid Key Vault Default Admin Object ID";
    }

    if (!config.api_extraction_func_name && config.api_extraction_storage_name) {
      errors.api_extraction_func_name = "Invalid API Extraction - Function Name";
    }

    if (config.api_extraction_func_name && !config.api_extraction_storage_name) {
      errors.api_extraction_func_name = "Invalid API Extraction - Storage Name";
    }


    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      setErrors({});
    }
    
    return errors;
  }, [config]);

  const onDeploy = useCallback(() => {
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setIsDeploying(true);
      submitJob("deploy").then(() => setIsDeploying(false));
    }
  }, [submitJob, validate, setIsDeploying]);

  return (
    <>
      {dwaData && (
        <CommandBar>
          <Breadcrumb items={breadcrumbs} />
          <Spacer />
          <CommandButton
            iconProps={{ iconName: "Redeploy" }}
            text="Publish"
            onClick={onDeploy}
            disabled={isDeploying}
          />
        </CommandBar>
      )}
      <div className="da-dwa">
        <Container>
          <DWAStandUpTabs />
          {activeTab !== "logs" && (
            <div className="da-panel da-grid-2-col">
              <TextField
                label="Datahub Name"
                errorMessage={errors.datahub_name}
                value={config.datahub_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, datahub_name: newValue }))}
              />
              <Dropdown
                label="Region Location"
                options={regionOptions}
                errorMessage={errors.rg_location}
                selectedKey={config.rg_location}
                onChange={(e, newValue) => setConfig(config => ({ ...config, rg_location: (newValue!.key + "") }))}
              />
              <TextField
                label="Subscription ID"
                errorMessage={errors.subscription_id}
                value={config.subscription_id}
                onChange={(e, newValue) => setConfig(config => ({ ...config, subscription_id: newValue }))}
              />
              <TextField
                label="Resource Group"
                errorMessage={errors.rg_name}
                value={config.rg_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, rg_name: newValue }))}
              />
              <TextField
                label="Keyvault Name"
                errorMessage={errors.keyvault_name}
                value={config.keyvault_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, keyvault_name: newValue}))}
              />
              <TextField
                label="Storage Name"
                errorMessage={errors.storage_name}
                value={config.storage_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, storage_name: newValue }))}
              />
              <TextField
                label="Data Factory Name"
                errorMessage={errors.datafactory_name}
                value={config.datafactory_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, datafactory_name: newValue }))}
              />
              <TextField
                label="Databricks Workspace Name"
                errorMessage={errors.databricks_name}
                value={config.databricks_name}
                onChange={(e, newValue) => setConfig(config => ({ ...config, databricks_name: newValue }))}
              />
              <TextField
                label="Databricks Managed Resource Group"
                errorMessage={errors.databricks_managed_resource_group}
                value={config.databricks_managed_resource_group}
                onChange={(e, newValue) =>
                  setConfig(config => ({ ...config, databricks_managed_resource_group: newValue }))
                }
              />
              <TextField
                label="Tenant ID"
                errorMessage={errors.tenant_id}
                value={config.tenant_id}
                onChange={(e, newValue) =>
                  setConfig(config => ({ ...config, tenant_id: newValue }))
                }
              />
              <TextField
                label="Key Vault Default Admin Object ID"
                errorMessage={errors.kv_default_admin_object_id}
                value={config.kv_default_admin_object_id}
                onChange={(e, newValue) =>
                  setConfig(config => ({ ...config, kv_default_admin_object_id: newValue }))
                }
              />
              <TextField
                label="API Extraction - Azure Function Name"
                errorMessage={errors.api_extraction_func_name}
                value={config.api_extraction_func_name}
                onChange={(e, newValue) =>
                  setConfig(config => ({ ...config, api_extraction_func_name: newValue }))
                }
              />
              <TextField
                label="API Extraction - Storage Name"
                errorMessage={errors.api_extraction_storage_name}
                value={config.api_extraction_storage_name}
                onChange={(e, newValue) =>
                  setConfig(config => ({ ...config, api_extraction_storage_name: newValue }))
                }
              />
            </div>
          )}
          <div className='da-logs-container' style={{ display: activeTab === 'logs' ? 'block' : 'none' }}>			
            <JobsTab />
					</div>
        </Container>
      </div>
    </>
  );
};

export default DWAStandUpPage;
