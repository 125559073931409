import { selector } from 'recoil';
import { dwaDatasetFilters } from '../atoms/dwaDatasetFilters';

export const dwaActiveTab = selector<any>({
	key: 'dwaActiveTab',
	get: ({get}) => {
		const datasetFilter = get(dwaDatasetFilters);
		return datasetFilter.status;
	},
	set: ({get, set}, newValue) => {
		const datasetFilter = get(dwaDatasetFilters);
		set(dwaDatasetFilters, {...datasetFilter, status: newValue});
	} 
});