import { ITypeValues } from "types/ITypeValues";

export const typeOptions: Array<{key: string; text: string;}> = [
	{
		key: 'fulladdupdatedelete', 
		text:'Full - Add, Update, Delete',
	},
	{
		key: 'deltaaddupdatedelete', 
		text:'Delta - Add, Update, Delete',
	},
	{
		key: 'fulladdupdate', 
		text:'Full - Add, Update',
	},
	{
		key: 'deltaaddupdate', 
		text:'Delta - Add, Update',
	},
	{
		key: 'fullappend', 
		text:'Full - Append',
	},
	{
		key: 'deltaappend', 
		text:'Delta - Append',
	},
];

export const typeValues: Array<{key: string; values: ITypeValues}> = [
	{ 
		key: 'fulladdupdatedelete', 
		values: {
			extraction_type: 'full',
			load_type: 'addupdatedelete'
		},
	},
	{ 
		key: 'deltaaddupdatedelete', 
		values: {
			extraction_type: 'delta',
			load_type: 'addupdatedelete'
		},
	},
	{ 
		key: 'fulladdupdate', 
		values: {
			extraction_type: 'full',
			load_type: 'addupdate'
		},
	},
	{ 
		key: 'deltaaddupdate', 
		values: {
			extraction_type: 'delta',
			load_type: 'addupdate'
		},
	},
	{ 
		key: 'fullappend', 
		values: {
			extraction_type: 'full',
			load_type: 'append'
		},
	},
	{ 
		key: 'deltaappend', 
		values: {
			extraction_type: 'delta',
			load_type: 'append'
		},
	},
	
];