import React from 'react';
import './Maintenance.css';

const Maintenance: React.FC = () => {
	return (
		<div className='da-maintenance'>
			<h1>Site Under Maintenance</h1>
			<p>Please check back soon</p>
		</div>
	)
};

export default Maintenance;