import React from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { Pivot, PivotItem } from '@fluentui/react';

import { dwaDatasets } from 'recoil/atoms/dwaDatasets';
import { dwaSelectedDatasets } from 'recoil/atoms/dwaSelectedDatasets';

import { dwaActiveTab } from 'recoil/selectors/dwaActiveTab';
import { dwaDatasetsConflictCount } from 'recoil/selectors/dwaDatasetsConflictCount';

import './DWAS2MTabs.css';

export const DWAS2MTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useRecoilState(dwaActiveTab);

  const { 
    managedCount, 
    unmanagedCount, 
    removedCount 
  } = useRecoilValue(dwaDatasets);
  
  const { managedConflicts, unmanagedConflicts } = useRecoilValue(dwaDatasetsConflictCount);
  const setSelectedDatasets = useSetRecoilState(dwaSelectedDatasets);

  const getPivotTextColor = (conflicts: number) : object => {
    return { color: conflicts > 0 ? 'var(--warning)' : 'var(--neutralPrimary)' }
  };

  const getPivotItemIcon = (conflicts: number): string | undefined  => {
    return conflicts ? 'warning': undefined;
  }

  const onTabChange = (item: PivotItem | undefined) => {
    setActiveTab(item!.props.itemKey!);
    setSelectedDatasets([]);
  }

  return (
    <div className='da-panel-tabs'>
      <Pivot
        linkSize={'large'}
        headersOnly={true}
        selectedKey={activeTab}
        onLinkClick={onTabChange}
      >
        <PivotItem headerText='General Settings' itemKey='settings' />
        <PivotItem
          headerText={`Managed Datasets (${managedCount})`}
          itemKey='managed'
          itemIcon={getPivotItemIcon(managedConflicts)}
          headerButtonProps={{
            style: getPivotTextColor(managedConflicts)
          }}
        />
        <PivotItem
          headerText={`Unmanaged Datasets (${unmanagedCount})`}
          itemKey='unmanaged'
          itemIcon={getPivotItemIcon(unmanagedConflicts)}
          headerButtonProps={{
            style: getPivotTextColor(unmanagedConflicts)
          }}
        />
        {removedCount > 0 && (
          <PivotItem
            headerText={`Removed Datasets (${removedCount})`}
            itemKey='removed'
          />
        )}
        <PivotItem headerText={'Logs'} itemKey='logs' />
      </Pivot>
    </div>
  );
};
