import { IDataset } from 'types/IDataset';
import { IDWAConfigDataset } from 'types/IDWA';

export const getStatusCount = (datasets: IDWAConfigDataset[]) => {
    return (
      datasets.reduce((count: any, {status}) => {
        if (status === undefined) {
          return status;
        }

        const setCount = (name: string) => count[name] || 0;

        count['managed'] = setCount('managed');
        count['unmanaged'] = setCount('unmanaged');
        count['removed'] =setCount('removed');
        
        if(status === 'unmanaged' || status === 'removed') {
          count[status] += 1;
        } 
        else {
          count['managed'] += 1;
        }

        return count;
      }, {})
    )
};

export const sortDataset = (dataset: IDWAConfigDataset[] | IDataset[] | undefined): any[] => {
  if (dataset === undefined || dataset.length === 0) {
    return [];
  };

  return [...dataset].sort(({name: currentName}, {name: nextName}) => {
    let sortValue = 0;

    if (currentName < nextName) {
      sortValue = -1;
    }

    if (currentName > nextName) {
      sortValue = 1;
    }
   
    return sortValue;
  })
};