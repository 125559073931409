import React, { useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IColumn, DetailsList, SelectionMode } from "@fluentui/react";
import { IDatasource } from "../../../types/IDatasource";
import { IParams } from "../../../types/IParams";
import "./DWAList.css";

const columns: IColumn[] = [
  {
    key: "name",
    name: "Datasource Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "type",
    name: "Datasource Type",
    fieldName: "type",
    minWidth: 100,
    maxWidth: 200,
  },
];

export const DWAList: React.FC<{ data: (IDatasource | null)[] }> = ({
  data,
}) => {
  const history = useHistory();
  const { solutionId, envId } = useParams<IParams>();

  const items = useMemo(
    () =>
      data
        .filter((item) => item?.config.s2m_dwa_id)
        .map((item) =>
          item
            ? {
                key: item.config.s2m_dwa_id,
                id: item.id,
                name: item.name,
                type: item.type,
              }
            : null
        ),
    [data]
  );

  const onClick = useCallback(
    (item: { key: string }) => {
      history.push(
        `/solution/${solutionId}/environment/${envId}/dwa/${item.key}`
      );
    },
    [history, solutionId, envId]
  );

  return (
    <div className="da-dwa-list">
      <DetailsList
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
        onActiveItemChanged={onClick}
      />
    </div>
  );
};
