import { atomFamily, selector, SerializableParam } from 'recoil';
import { coreApiEndpoint, apiVersion } from '../../constants/coreApiEndpoint';
import { ISolution } from '../../types/ISolution';
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { noopPromise } from "./noopPromise";

export type ISolutionParam = SerializableParam & {
	solutionId: string;
}

export const solutionAtom = atomFamily<ISolution | null, ISolutionParam>({
	key: 'solution',
	default: noopPromise(null),
	effects_UNSTABLE:[
		fetchRemoteEffect<ISolutionParam>(({solutionId}) => `${coreApiEndpoint}${apiVersion}/solutions/${solutionId}`)
	]
});

export const solution = selector<ISolution | null>({
	key: 'solution/proxy',
	get: async ({ get }) => {
		const solutionId = get(selectedSolutionId);
		if (solutionId) {
			return get(solutionAtom({ solutionId }));
		}
		return null;
	}
});