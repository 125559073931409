import {atom} from 'recoil';

interface IDWAUpdateStatus {
	isUpdating: boolean;
}

export const dwaUpdateStatus = atom<IDWAUpdateStatus>({
	key: 'dwaUpdateStatus',
	default: {
		isUpdating: false
	}
});