import { selector } from 'recoil';
import { dwaDatasets } from '../atoms/dwaDatasets';
interface IDWAModifiedDatasetsDetailedCount {
	willBeAdded: number;
	willBeUpdated: number;
}

export const dwaModifiedDatasetsDetailedCount = selector<IDWAModifiedDatasetsDetailedCount>({
	key: 'dwaModifiedDatasetsDetailedCount',
	get: ({get}) => {
		const datasets = get(dwaDatasets);
		return datasets.data.reduce((count, dataset) => {
			if (dataset.modified) {
				if (dataset.status === 'changed' || dataset.status === 'unchanged')	{
					return {...count, willBeUpdated: count.willBeUpdated + 1};
				} else if (dataset.status === 'unmanaged') {
					return {...count, willBeAdded: count.willBeAdded + 1};
				}
			}
			return count;
		}, { willBeAdded: 0, willBeUpdated: 0 });
	}
});