import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Dropdown, MessageBar, MessageBarType, TextField } from '@fluentui/react';

import { useLocalUpdateDataset } from 'hooks/useLocalUpdateDataset';

import { dwaSelectedDataset } from 'recoil/atoms/dwaSelectedDataset';
import { dwaUpdateStatus } from 'recoil/atoms/dwaUpdateStatus';

import { 
    getBusinessKeys, 
    getColumnOptions,
    getSelectedType,
    getSelectedTypeValues,
    isDatasetValid,
    areBusinessKeysVisible,
    areWatermarkFieldsVisible,
    areDeleteWatermarkFieldsVisible,
} from 'components/utils/DWAS2MHelper';

import { deltaWatermarkTypeOptions, initialDeltaWatermarkValues } from 'enums/deltaWatermark'
import { typeOptions } from 'enums/typeOptions';

export const DWAS2MPanelForm: React.FC = () => {
    const [selectedDataset, setSelectedDataset] =
        useRecoilState(dwaSelectedDataset);

    const updateDataset = useLocalUpdateDataset();
    
    const updateStatus = useRecoilValue(dwaUpdateStatus);
    
    const {
        business_keys,
        delta_watermark_column,
        delta_watermark_offset_type,
        delta_watermark_offset,
        delete_delta_watermark_column,
        delete_delta_watermark_offset_type,
        delete_delta_watermark_offset,
        extraction_type,
        load_type,
        status
    } = selectedDataset || {};
    
    const setDataSet = useCallback((dataSetValues: any) =>{
        if (!selectedDataset) return;

        const updatedDataSet = {
            ...selectedDataset,
            ...dataSetValues,
        };

        if (selectedDataset?.name) {
            updateDataset(selectedDataset.name, dataSetValues);
            setSelectedDataset(updatedDataSet);
        }
    }, [selectedDataset, setSelectedDataset, updateDataset]);
    
    const onTypeChanged = useCallback((_: any, type: any) => {
        const { extraction_type, load_type } = getSelectedTypeValues(type.key) || {};
        
        setDataSet({
            ...initialDeltaWatermarkValues,
            extraction_type,
            load_type,
        })
    }, [setDataSet]);

    const onBusinessKeysChanged = useCallback((_: any, keys: any) => {
        if (selectedDataset) 
            setDataSet({ business_keys: getBusinessKeys(selectedDataset.business_keys, keys) })
	}, [selectedDataset, setDataSet]);

    const onDropdownChange = useCallback((fieldKey: string | number | undefined, fieldName: string) => {
        if (fieldKey) 
            setDataSet({[fieldName]: fieldKey})
	}, [setDataSet]);
    
    const onTextboxChange = useCallback((offset: string | undefined, fieldName: string) => {
        setDataSet({ [fieldName]: parseInt(offset ?? '0') })
	}, [setDataSet]);

    const columnOptions = useMemo(() => {
        return getColumnOptions(selectedDataset?.columns ?? [])
    }, [selectedDataset]); 

    const getTextFieldValue = (value: number | undefined) => value?.toString() ?? '';

    const isDisabled = status === 'removed' || updateStatus.isUpdating;
    const isValid = isDatasetValid(selectedDataset);

    const dropdownStyles = {
        title: {borderColor: !isValid ? 
            'var(--warning)' : 'var(--neutralSecondary)'},
    };

    const requiredFieldProps = {
        disabled: isDisabled,
        options: columnOptions,
        required: true,
        styles: dropdownStyles,
    }

    return (
        <div className='da-panel settings'>
            {!isValid && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                >
                    Please fill up all the required fields.
                </MessageBar>
            )}
            <form>
                <Dropdown
                    label='Type'
                    selectedKey={getSelectedType(selectedDataset)}
                    options={typeOptions}
                    onChange={onTypeChanged}
                    disabled={isDisabled}
                />
                {areBusinessKeysVisible(extraction_type, load_type) && (
                    <Dropdown
                        {...requiredFieldProps}
                        label='Business Keys'
                        multiSelect
                        selectedKeys={business_keys}
                        onChange={onBusinessKeysChanged}
                    />
                )}
                {areWatermarkFieldsVisible(extraction_type) && (
                    <>
                        <Dropdown
                            {...requiredFieldProps}
                            label='Delta Watermark Column'
                            selectedKey={delta_watermark_column}
                            onChange={(_, newValue) => 
                                onDropdownChange(newValue?.key, 'delta_watermark_column')
                            }
                        />
                        <Dropdown
                            label='Delta Watermark Offset Type'
                            disabled={isDisabled}
                            options={deltaWatermarkTypeOptions}
                            selectedKey={delta_watermark_offset_type}
                            onChange={(_, newValue) => 
                                onDropdownChange(newValue?.key, 'delta_watermark_offset_type')
                            }
                        />
                        <TextField 
                            label={`Delta Watermark Offset ${delta_watermark_offset_type === 'date' ? '(by seconds)' : ''}`}
                            type='number' 
                            value={getTextFieldValue(delta_watermark_offset)} 
                            onChange={(e, newValue) => 
                                onTextboxChange(newValue, 'delta_watermark_offset')
                            }
                        />
                    </>
                )}
                {areDeleteWatermarkFieldsVisible(extraction_type, load_type) && (
                    <>
                        <Dropdown
                            {...requiredFieldProps}
                            label='Delete Delta Watermark Column'
                            selectedKey={delete_delta_watermark_column}
                            onChange={(_, newValue) => 
                                onDropdownChange(newValue?.key, 'delete_delta_watermark_column')
                            }
                        />
                        <Dropdown
                            label='Delete Delta Watermark Offset Type'
                            disabled={isDisabled}
                            options={deltaWatermarkTypeOptions}
                            selectedKey={delete_delta_watermark_offset_type}
                            onChange={(_, newValue) => 
                                onDropdownChange(newValue?.key, 'delete_delta_watermark_offset_type')
                            }
                        />
                        <TextField 
                            label={`Delete Delta Watermark Offset ${delete_delta_watermark_offset_type === 'date' ? '(by seconds)' : ''}`}
                            type='number' 
                            value={getTextFieldValue(delete_delta_watermark_offset)} 
                            onChange={(e, newValue) => 
                                onTextboxChange(newValue, 'delete_delta_watermark_offset')
                            }
                        />
                    </>
                )}
            </form>
        </div>
    );
};
