import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CommandButton, Breadcrumb } from '@fluentui/react';

import { selectedDwaId } from 'recoil/atoms/selectedDwaId';
import { breadcrumbsAtom } from 'recoil/atoms/breadcrumbs';
import { deployStatusAtom } from 'recoil/atoms/dwaDeployStatus';

import { dwaDatasetsConflictCount } from 'recoil/selectors/dwaDatasetsConflictCount';
import { dwaModifiedDatasetsDetailedCount } from 'recoil/selectors/dwaModifiedDatasetsDetailedCount';

import { usePublishDWA } from 'hooks/usePublishDWA';

import { Spacer } from 'components/common/Spacer';
import { CommandBar } from 'components/common/CommandBar';

import { DEPLOY_IDLE } from 'constants/deployStatus';

import './DWAS2MCommandBar.css';

export const DWAS2MCommandBar: React.FC = () => {
	const history = useHistory();

	const dwaId = useRecoilValue(selectedDwaId);
	const breadcrumbs = useRecoilValue(breadcrumbsAtom);
	const deployStatus = useRecoilValue(deployStatusAtom({ dwaId }));
	
	const { managedConflicts, unmanagedConflicts } = useRecoilValue(dwaDatasetsConflictCount);
	const { willBeAdded, willBeUpdated } = useRecoilValue(dwaModifiedDatasetsDetailedCount);

	const submitJob = usePublishDWA();

	const totalConflicts = managedConflicts + unmanagedConflicts;

	const totalModified = willBeAdded + willBeUpdated;
	
	const withNotifications = [totalModified, totalConflicts].some(count => count > 0);

	const isNotIdle = deployStatus !== DEPLOY_IDLE;
	const isDatasetValid = totalConflicts === 0;

	const getNotifications = (): string => {
		let notifs: string[] = [];

		if (!isDatasetValid) {
			notifs = [...notifs, `${totalConflicts} Invalid dataset`];
		}

		if (willBeAdded > 0) {
			notifs = [...notifs, `${willBeAdded} will be added`];
		}
	
		if (willBeUpdated > 0) {
			notifs = [...notifs, `${willBeUpdated} will be updated`];
		}

		return `${notifs.join(', ')}.`;
	}

	return (
		<CommandBar>
			<Breadcrumb items={breadcrumbs} />
			<Spacer />
			{withNotifications &&
				<span className='da-modified-datasets-count'>
					Datasets: {getNotifications()}
				</span>
			}
			<CommandButton
				iconProps={{ iconName: 'Undo' }}
				text='Discard Changes'
				disabled={isNotIdle || totalModified === 0}
				onClick={() => history.push('/refresh')}
			/>
			<CommandButton
				iconProps={{ iconName: 'Redeploy' }}
				text='Publish'
				disabled={isNotIdle || !isDatasetValid}
				onClick={() => submitJob('deploy')}
			/>
		</CommandBar>
	)
};