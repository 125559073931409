import { IColumn } from '@fluentui/react';

export const watermarksColumnFields: IColumn[] = [
	{
		key: 'ts',
		fieldName: 'ts',
		name: 'Timestamp',
		minWidth: 0
	},
	{
		key: 'watermark',
		fieldName: 'watermark',
		name: 'Watermark',
		minWidth: 0
	},
	{
		key: 'active',
		fieldName: 'active',
		name: 'Active',
		minWidth: 0
	},
	{
		key: 'actions',
		fieldName: 'actions',
		name: 'Actions',
		minWidth: 0
	},
];
