import { atomFamily, selector, SerializableParam } from "recoil";
import { IJobLog } from "../../types/IJob";
import { selectedDwaId } from "../atoms/selectedDwaId";
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";
import { selectedJobLogsId } from "../atoms/selectedJobLogs";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { noopPromise } from "./noopPromise";

export type IJobLogsParam = SerializableParam & {
  endpoint: string;
  solutionId: string;
  environmentId: string;
  dwaId: string;
  jobId: string;
};

export const jobLogsAtom = atomFamily<IJobLog[], IJobLogsParam>({
  key: "jobLogs",
  default: noopPromise([]),
  effects_UNSTABLE: [
    fetchRemoteEffect<IJobLogsParam>(
      ({ endpoint, solutionId, environmentId, dwaId, jobId }) => 
        `${endpoint}/${solutionId}/environments/${environmentId}/dwas/${dwaId}/jobs/${jobId}/logs`,
      (result) => {
        if (result && result.items) {
          let items = result.items as IJobLog[];
          items = items.sort((a, b) => {
            if (a.Timestamp < b.Timestamp) {
              return -1;
            }
            if (a.Timestamp > b.Timestamp) {
              return 1;
            }
            return 0;
          });
          return items;
        } else if (result && result instanceof Array) {
          let items = result as IJobLog[];
          items = items.sort((a, b) => {
            if (a.Timestamp < b.Timestamp) {
              return -1;
            }
            if (a.Timestamp > b.Timestamp) {
              return 1;
            }
            return 0;
          });
          return items;
        }
        return [];
      }
    )
  ]
});

export const jobLogs = selector<IJobLog[]>({
  key: "jobLogs/proxy",
  get: async ({ get }) => {
    const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		const dwaId = get(selectedDwaId);
    const jobId = get(selectedJobLogsId);

    if (endpoint && environmentId && dwaId && jobId) {
      return get(jobLogsAtom({ endpoint, solutionId, environmentId, dwaId, jobId }));
    }

    return [];
  },
});
