import { atom } from 'recoil';
import { coreApiEndpoint, apiVersion } from '../../constants/coreApiEndpoint';
import { ISolution } from '../../types/ISolution';
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { noopPromise } from "./noopPromise";

export const solutions = atom<ISolution[]>({
	key: 'solutions',
	default: noopPromise([]),
	effects_UNSTABLE: [
		fetchRemoteEffect(() => `${coreApiEndpoint}${apiVersion}/solutions`)
	]
});
