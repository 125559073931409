import { atomFamily, selector, SerializableParam } from 'recoil';
import { IDatasource } from '../../types/IDatasource';
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { noopPromise } from "./noopPromise";

export type IDatasourcesParams = SerializableParam & {
	endpoint: string;
	solutionId: string;
	environmentId: string;
}

export const datasourcesAtom = atomFamily<IDatasource[], IDatasourcesParams>({
	key: 'datasources',
	default: noopPromise([]),
	effects_UNSTABLE:[
		fetchRemoteEffect<IDatasourcesParams>(({ endpoint, solutionId, environmentId }) => 
			`${endpoint}/${solutionId}/environments/${environmentId}/datasources`)
	]
});

export const datasources = selector<IDatasource[]>({
	key: 'datasources/proxy',
	get: async ({ get }) => {	
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		
		if (endpoint && environmentId) {
			return get(datasourcesAtom({ endpoint, solutionId, environmentId }));
		}

		return [];
	}
});