import {atom} from "recoil";
import { localStorageEffect } from "../effects/localStorageEffect";
import { syncUrlParamsEffect } from "../effects/syncUrlParamsEffect";

export const selectedSolutionId = atom({
	key: 'selectedSolutionId',
	default: '',
	effects_UNSTABLE: [
		localStorageEffect, //('selectedSolutionId'),
		syncUrlParamsEffect('/solution/:solutionId', 'solutionId')
	]
});