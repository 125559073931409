import { standardHeaders } from "./standardHeaders";

export const createDatasource = async (apiEndpoint: string, solutionId: string, name: string, type: string) => {
	const response = await fetch(`${apiEndpoint}/${solutionId}/datasources/create`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify({ name, type })
	});
	
	return response;
}
