import { selector } from 'recoil';
import { apiVersion } from "../../constants/coreApiEndpoint";
import { ISolution } from '../../types/ISolution';
import { solution } from './solution';

export const apiEndpoint = selector<string | null>({
	key: 'apiEndpoint',
	get: async ({ get }) => {
		const solutionData = get(solution) as ISolution;
		return solutionData?.agent_api ? solutionData?.agent_api + apiVersion : null;
	}
});