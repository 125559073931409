import { atomFamily, SerializableParam } from 'recoil';
import { DEPLOY_UNKNOWN } from "../../constants/deployStatus";

export type IDWADeployStatusParams = SerializableParam & {
	dwaId: string;
}

export const deployStatusAtom = atomFamily<string, IDWADeployStatusParams>({
	key: 'deployStatus',
	default: DEPLOY_UNKNOWN
});
