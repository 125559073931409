import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Dropdown, Position, SpinButton, TextField } from "@fluentui/react";

import { dwaConfigSettings } from "recoil/atoms/dwaConfigSettings";

import { dwa } from "recoil/remote/dwa";

import { extractionFrequencyOptions } from "enums/extractionFrequencyOptions";
import { s2mSQLSourceDatasetTypeOptions } from "enums/s2mSQLSourceDatasetTypeOptions";
import { s2mSQLSourceDialectTypeOptions } from "enums/s2mSQLSourceDialectTypeOptions";
import { s2mVersionOptions } from "enums/s2mVersionOptions";

const shiftFrequencyDown = (frequency: string) => {
  switch (frequency) {
    case "day":
      return "hour";
    case "week":
    case "month":
      return "day";
    default:
      return "";
  }
};

const offsetSuffix = (frequency: string) => frequency + "(s)";

const onDecrementSpinButton = (value: string) => {
  const parsedValue = parseFloat(value);
  return parsedValue - 1 < 0 ? parsedValue : parsedValue - 1;
};

const onIncrementSpinButton = (value: string) => parseFloat(value) + 1;

const onValidateOffsetSpinButton = (
  value: string,
  frequency: string,
  extraction_frequency: string
) => {
  const parsedValue = parseFloat(value);

  if (parsedValue <= 0 || isNaN(parsedValue)) {
    return "0 " + offsetSuffix(shiftFrequencyDown(frequency));
  }

  return (
    parsedValue + " " + offsetSuffix(shiftFrequencyDown(extraction_frequency))
  );
};

export const SQLSettings: React.FC = () => {
  const [config, setConfig] = useRecoilState(dwaConfigSettings);

  const dwaData = useRecoilValue(dwa);

  const extractionFrequency =
    config.extraction_frequency ??
    (extractionFrequencyOptions[0].key as string);

  const frequency = shiftFrequencyDown(extractionFrequency);

  return (
    <div className="da-panel">
      <form>
        <div style={{ display: "none" }}>
          <Dropdown
            label="Extraction Frequency"
            selectedKey={config.extraction_frequency}
            onChange={(_, item) =>
              setConfig((state) => ({
                ...state,
                extraction_frequency: item?.key + "",
              }))
            }
            options={extractionFrequencyOptions}
          />
          <SpinButton
            label="Extraction Frequency Offset"
            value={
              config.extraction_frequency
                ? (config.extraction_frequency_offset ?? 0) +
                  " " +
                  offsetSuffix(frequency)
                : ""
            }
            min={0}
            onValidate={(value: string) =>
              onValidateOffsetSpinButton(value, frequency, extractionFrequency)
            }
            onIncrement={(value: string) => {
              const newValue = onIncrementSpinButton(value);
              setConfig((state) => ({
                ...state,
                extraction_frequency_offset: newValue,
              }));
            }}
            onDecrement={(value: string) => {
              const newValue = onDecrementSpinButton(value);
              setConfig((state) => ({
                ...state,
                extraction_frequency_offset: newValue,
              }));
            }}
            labelPosition={Position.top}
            disabled={!config.extraction_frequency}
          />
        </div>

        <Dropdown
          label="Source Dataset Type"
          selectedKey={config.source_dataset_type}
          options={s2mSQLSourceDatasetTypeOptions}
          onChange={(_, item) =>
            setConfig((state) => ({
              ...state,
              source_dataset_type: item?.key + "",
            }))
          }
        />

        <Dropdown
          label="Version"
          selectedKey={config.version}
          options={s2mVersionOptions}
          onChange={(_, item) =>
            setConfig((state) => ({
              ...state,
              version: item?.key + "",
            }))
          }
        />

        {config.source_dataset_type === "ODBC" && (
          <Dropdown
            label="Source Dialect Type"
            selectedKey={config.source_dialect_type}
            options={s2mSQLSourceDialectTypeOptions}
            onChange={(_, item) =>
              setConfig((state) => ({
                ...state,
                source_dialect_type: item?.key + "",
              }))
            }
          />
        )}

        {config.source_dataset_type === "LINKEDSQLSERVER" && (
          <TextField
            label="Linked Server Name"
            value={config.source_dataset_linked_server_name}
            onChange={(e, newValue) =>
              setConfig((state) => ({
                ...state,
                source_dataset_linked_server_name: newValue!,
              }))
            }
          />
        )}

        {config.datasource_environment_id && (
          <>
            <TextField
              label="Data Source Environment ID"
              readOnly
              defaultValue={config?.datasource_environment_id}
            />
            <TextField
              label="DWA Environment  ID"
              readOnly
              defaultValue={dwaData?.dwa_environment_id}
            />
            <TextField
              label="DWA API Key"
              readOnly
              defaultValue={config.api_key}
            />
          </>
        )}

        {!config.datasource_environment_id && config.datasource_id && (
          <>
            <TextField
              label="Data Source ID"
              readOnly
              defaultValue={config?.datasource_id}
            />
            <TextField
              label="DWA Environment ID"
              readOnly
              defaultValue={config.dwa_environment_id}
            />
            <TextField
              label="DWA API Key"
              readOnly
              defaultValue={config.dwa_environment_api_key}
            />
          </>
        )}
      </form>
    </div>
  );
};
