import React, { useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MarqueeSelection, DetailsList, Selection, SelectionMode } from '@fluentui/react';
import { IDWAS2MGridProps } from "../../../../../types/IDWAS2MGridProps";
import { dwaUpdateStatus } from "../../../../../recoil/atoms/dwaUpdateStatus";
import { dwaActiveTab } from "../../../../../recoil/selectors/dwaActiveTab";
import { dwaFilteredDatasets } from "../../../../../recoil/selectors/dwaFilteredDatasets";
import { dwaSelectedDatasets } from "../../../../../recoil/atoms/dwaSelectedDatasets";
import { dwaSelectedDataset } from "../../../../../recoil/atoms/dwaSelectedDataset";
import { useLocalUpdateDataset } from "../../../../../hooks/useLocalUpdateDataset";
import { DWAGridContext } from "../../../../../providers/DWAGridContext";

export const DWAS2MGrid: React.FC<IDWAS2MGridProps> = ({
	datasetFields,
	rowRenderer,
	columnRenderer
}) => {
	const updateStatus = useRecoilValue(dwaUpdateStatus);
	const activeTab = useRecoilValue(dwaActiveTab);
	const filteredDatasets = useRecoilValue(dwaFilteredDatasets);
	const setSelectedDatasets = useSetRecoilState(dwaSelectedDatasets);
	const setSelectedDataset = useSetRecoilState(dwaSelectedDataset);
	const updateDataset = useLocalUpdateDataset();
	const selection = useRef(new Selection({
		onSelectionChanged: () => {
			if (selection.current) {
				setSelectedDatasets(selection.current.getSelection() as any);
			}
		}
	}));

	return (
		<DWAGridContext.Provider value={{updateStatus, updateDataset, setSelectedDataset}}>
			<MarqueeSelection selection={selection.current}>
				<DetailsList
					selection={selection.current}
					selectionPreservedOnEmptyClick={true}
					columns={datasetFields}
					items={filteredDatasets}
					selectionMode={activeTab !== 'removed' ? SelectionMode.multiple : SelectionMode.none}
					onRenderRow={rowRenderer}
					onRenderItemColumn={columnRenderer}
					onItemInvoked={(dataset) => {
						setSelectedDataset(dataset);
					}}
				/>
			</MarqueeSelection>
		</DWAGridContext.Provider>
	)
}