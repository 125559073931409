import React, { useReducer, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Persona, PersonaSize, PrimaryButton, Panel, PanelType, ComboBox, IComboBox, IComboBoxOption, IconButton } from '@fluentui/react';
import { useAzureAD } from '../../hooks/useAzureAD';
import { solutions } from '../../recoil/remote/solutions';
import { solution } from '../../recoil/remote/solution';
import { selectedSolutionId } from '../../recoil/atoms/selectedSolutionId';
import { mainNavVisible } from '../../recoil/atoms/mainNavVisible';
import './NavHeader.css';

const publicUrl = process.env.PUBLIC_URL;

const NavHeader: React.FC = () => {
	const history         = useHistory();
	const location        = useLocation();
	const { accountInfo } = useAzureAD();	
	const solutionsData   = useRecoilValue(solutions);
	const solutionId      = useRecoilValue(selectedSolutionId);
	const solutionData    = useRecoilValue(solution);
	const showMainNav     = useSetRecoilState(mainNavVisible);
	const [showClientPanel, toggleClientPanel] = useReducer(t => !t, false);

	const solutionOptions = useMemo(() =>
		solutionsData.map(solution => ({
			key: solution.id,
			text: `${solution.client_name} - ${solution.name}`
		})),
		[solutionsData] 
	);

	const onChange = useCallback(
		(ev: React.FormEvent<IComboBox>, selected?: IComboBoxOption) => {
			const solution = solutionsData.find(solution => solution.id === selected?.key);
			if (solution) {
				history.push(`/solution/${solution.id}`);
				toggleClientPanel();
			}
		},
		[solutionsData, toggleClientPanel, history],
	);

	useEffect(() => {
		if (solutionsData) {
			const found = solutionsData.find(solution => solution.id === solutionId);
			if (!found) {
				if (solutionsData.length > 0) {
					if (!location.pathname.includes('docs')) {
						history.push(`/solution/${solutionsData[0].id}`);
					}
				} else {
					history.push(`/accessdenied`);
				}
			} else {
				if (location.pathname.includes('manage') || location.pathname === '/') {
					history.push(`/solution/${solutionId}`);
				}
			}
		}
	}, [solutionsData, solutionId, history, location]);

	return (
		<nav className='da-navheader'>
			<IconButton
				className='da-nav-menu'
				primary
				iconProps={{ iconName: 'CollapseMenu' }}
				title="Menu"
				ariaLabel="Menu"
				onClick={() => showMainNav(true)}
			/>
			<div className='da-spacer da-1st-spacer' />
			<div className='da-logo-container'>
				<img src={`${publicUrl}/logo-small-white.png`} alt='DataAddiction' />
			</div>
			<div className='da-vertical-separator' />
			<div className='da-product-title'>LakeFlow</div>
			<div className='da-spacer da-2nd-spacer' />
			<PrimaryButton onClick={toggleClientPanel}>
				{solutionData ? `${solutionData.client_name} - ${solutionData.name}` :  'Click here to select a Solution'}
			</PrimaryButton>
			<div className='da-spacer da-3rd-spacer' />
			<div className='da-persona'>
				<Persona text={accountInfo?.account.name} size={PersonaSize.size32} hidePersonaDetails={true} />
			</div>
			<Panel
				type={PanelType.smallFixedFar}
				isOpen={showClientPanel}
				onDismiss={toggleClientPanel}
				headerText='Choose a Solution'
				isLightDismiss={true}
			>
				<div className='da-client-panel'>
					<ComboBox
						label='Solution'
						options={solutionOptions}
						autoComplete='on'
						allowFreeform={true}
						selectedKey={solutionId}
						onChange={onChange}
						useComboBoxAsMenuWidth={true}
					/>
				</div>
			</Panel>
		</nav>
	)
};

export default NavHeader;