import React, { useCallback, Suspense } from 'react';
import { Route, Switch, useHistory, Redirect, useLocation } from 'react-router-dom';
import { Nav, INavLinkGroup, INavLink, Spinner, SpinnerSize, Panel, PanelType } from '@fluentui/react';
import { useRecoilState } from "recoil";
import AccessDenied from "../common/AccessDenied";
import NavHeader from "../common/NavHeader";
import RefreshPage from '../pages/RefreshPage';
import DocumentationPage from '../pages/DocumentationPage';
import ManagePage from "../pages/manage/ManagePage";
import EnvironmentPage from "../pages/environment/EnvironmentPage";
import DWAPage from "../pages/dwa/DWAPage";
import { mainNavVisible } from '../../recoil/atoms/mainNavVisible';
import './Main.css';

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Manage',
				url: '/manage',
				key: 'manage'
      },
      {
        name: 'Documentation',
        url: '/docs',
        key: 'docs', 
      }
    ]
  }
];

const Main: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [showNavPanel, setShowNavPanel] = useRecoilState(mainNavVisible);

  const onNavigate = useCallback((e?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    e?.preventDefault();
    if (item && location.pathname.indexOf(item.url) !== 0) {
      history.push(item.url);
      setShowNavPanel(false);
    }
  }, [history, location, setShowNavPanel]);
  
	return (
		<div className='da-main'>
      <Switch>
        <Route path='/accessdenied' component={AccessDenied} />
        <Route path={'/'}>
            <Panel
              isLightDismiss
              isOpen={showNavPanel}
              onDismiss={() => setShowNavPanel(false)}
              closeButtonAriaLabel="Close"
              type={PanelType.smallFixedNear}
            >
              <Nav groups={navLinkGroups} onLinkClick={onNavigate} />
            </Panel>
          <NavHeader />
          <main>
            <div className='da-sidebar'>
              <Nav groups={navLinkGroups} onLinkClick={onNavigate} />
            </div>
            <Suspense fallback={<div className='da-general-loading'>
              <Spinner size={SpinnerSize.large} />
            </div>}>
              <Switch>
                {/* <Route path='/home' component={HomePage} />
                <Route path='/manage' component={ManagePage} exact /> */}
                {/* <Route path='/manage'><Redirect to='/solution/:solutionId' /></Route> */}
                <Route path='/solution/:solutionId' component={ManagePage} exact />
                <Route path='/solution/:solutionId/environment/:envId' component={EnvironmentPage} exact />
                <Route path={[
                  '/solution/:solutionId/environment/:envId/dwa/:dwaId',
                  '/solution/:solutionId/environment/:envId/dwa/:dwaId/jobs/:jobId'
                ]} component={DWAPage} exact />
                <Route path='/solution/:solutionId/docs' component={DocumentationPage} exact />
                <Route path='/refresh' component={RefreshPage} exact />
                <Route path='/docs' component={DocumentationPage} />
              </Switch>
            </Suspense>
          </main>
        </Route>
        <Redirect to={'/accessdenied'} />
      </Switch>
		</div>
	)
}

export default Main;