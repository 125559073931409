import React from 'react';
import { SearchBox } from '@fluentui/react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { dwaActiveTab } from 'recoil/selectors/dwaActiveTab';
import { dwaSearch } from 'recoil/selectors/dwaSearch';

import { DWAS2MBatchControl } from './DWAS2MBatchControl';
import { DWAS2MFilterButton } from './DWAS2MFilterButton';

import { Spacer } from 'components/common/Spacer';

import './DWAS2MListOptions.css';

export const DWAS2MListOptions: React.FC = () => {
	const activeTab = useRecoilValue(dwaActiveTab);
	
	const [search, setSearch] = useRecoilState(dwaSearch);

	return (
		<div className='da-dataset-list-options'>
			{activeTab !== 'removed' && <DWAS2MBatchControl />}
			<Spacer />
			{activeTab === 'managed' && <DWAS2MFilterButton />}
			<SearchBox
				placeholder='Search'
				defaultValue={search}		
				onChange={(_, newValue) => setSearch(newValue)}
				styles={{ root: { width: 300 } }}
			/>
		</div>
	);
};