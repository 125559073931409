import React, { useEffect, useRef, useState } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useRecoilValueLoadable, useResetRecoilState } from "recoil";
import moment from "moment";
import { IJobLog } from "../../../../../../types/IJob";
import { useSelectedParams } from "../../../../../../hooks/useSelectedParams";
import { job } from "../../../../../../recoil/remote/job";
import { jobLogs, jobLogsAtom } from "../../../../../../recoil/remote/jobLogs";
import { JOB_IN_PROGRESS, JOB_NOT_STARTED } from "../../../../../../constants/jobStatus";

export const JobLogsPanel: React.FC = () => {
  const [cachedData, setCachedData] = useState<IJobLog[] | null>(null);
  const lastState      = useRef('');
  const params         = useSelectedParams();
  const selectedJob    = useRecoilValueLoadable(job);
  const remoteData     = useRecoilValueLoadable(jobLogs);
  const refreshJobLogs = useResetRecoilState(jobLogsAtom({ ...params }));

  useEffect(() => {
    refreshJobLogs();
  }, [refreshJobLogs]);

  useEffect(() => {
    if (lastState.current !== 'hasValue') {
      if (remoteData.state === 'hasValue') {
        setCachedData(remoteData.contents);
      }
    }
    lastState.current = remoteData.state;
  }, [remoteData, setCachedData]);

  useEffect(() => {

    const refreshCallback = () => {
      if (selectedJob.state === 'hasValue') {
        if (
          selectedJob.contents?.statusText === JOB_NOT_STARTED ||
          selectedJob.contents?.statusText === JOB_IN_PROGRESS
        ) {
          refreshJobLogs();
        }
      }
    };

    const pollId = window.setInterval(refreshCallback, 3000);

    return () => {
      window.clearInterval(pollId);
    };
  }, [refreshJobLogs, selectedJob]);

  return (
    cachedData ? <>
      {cachedData.length > 0 ?
        cachedData.map((log: IJobLog) => {
          const date = moment(log.Timestamp);
          return (
            <div key={log.RowKey} style={{ marginTop: "1em" }}>
              <span style={{ fontWeight: "bold" }}>
                [{date.local().format("YYYY-MM-DD HH:mm:ss")}]
              </span>{" "}
              {log.message}
            </div>
          );
        }) : <p>No logs yet</p>
      }
    </> : <div className='da-general-loading'>
      <Spinner size={SpinnerSize.large} />
    </div>
  );
};
