import React, { useState, useCallback } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Breadcrumb, IBreadcrumbItem, CommandButton, Panel, TextField, Dropdown, PrimaryButton, DefaultButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useSelectedParams } from "../../../hooks/useSelectedParams";
import { environment } from "../../../recoil/remote/environment";
import { datasources, datasourcesAtom } from "../../../recoil/remote/datasources";
import { createDatasource } from "../../../recoil/remote/createDatasource";
import { CommandBar } from "../../common/CommandBar";
import { Spacer } from "../../common/Spacer";
import { Container } from "../../common/Container";
import { DWAList } from "./DWAList";

import {sortArrayValue} from 'components/utils/DWAS2MHelper'

import './EnvironmentPage.css';

const datasourceTypeOptions = [
	{ key: 'sql server', text: 'SQL Server' },
	{ key: 'blob', text: 'Blob' },
	{ key: 'd365', text: 'Dynamics 365' }
];

export const EnvironmentPage: React.FC = () => {
	const history            = useHistory();
	const location           = useLocation();
	const params             = useSelectedParams();
	const environmentData    = useRecoilValue(environment);
	const datasourcesData    = useRecoilValue(datasources);
	const refreshDatasources = useResetRecoilState(datasourcesAtom(params));

	const [panelOpen, setPanelOpen]       = useState(false);
	const [newDWA, setNewDWA]             = useState({ name: '', type: datasourceTypeOptions[0].key });
	const [errors, setErrors]             = useState({ name: '', type: '' });
	const [generalError, setGeneralError] = useState('');
	const [isSaving, setIsSaving]         = useState(false);

	const breadcrumbs: IBreadcrumbItem[] = [
		{ text: 'Manage', key: 'Manage', onClick: () => history.push('/manage') }
	];

	const onCreate = useCallback(async () => {
		const errors: any = {};
		setGeneralError('');

		if (!newDWA.name) {
			errors.name = 'Name is required';
		}
		if (!newDWA.type) {
			errors.type = 'Type is required';
		}
		if (Object.keys(errors).length > 0) {
			setErrors(errors);
			return;
		} else {
			setErrors({ name: '', type: ''});
		}
		
		setIsSaving(true);
		const response = await createDatasource(params.endpoint, params.solutionId, newDWA.name, newDWA.type);
		if (response.ok) {
			refreshDatasources();
			setPanelOpen(false);
		} else {
			setGeneralError('Unable to create a new datasource. Please try again later.');
		}
		setIsSaving(false);
	}, [newDWA, setErrors, refreshDatasources, params.solutionId, setPanelOpen, setGeneralError, setIsSaving, params.endpoint]);

	if (environmentData) {
		breadcrumbs[1] = {
			text: environmentData.name,
			key: 'Environment'
		};
	} 

	const sortedDatasources = [...datasourcesData].sort(({name: currentName}, {name: nextName}) => {
        return sortArrayValue(currentName, nextName)
    })

	return (
		<div className='da-env-page'>
			{environmentData && <>
				<CommandBar>
					<Breadcrumb items={breadcrumbs} maxDisplayedItems={2} />
					<Spacer />
					<Link to={location.pathname + '/dwa/' + environmentData.config.environment_standup_dwa_id}>
						<CommandButton iconProps={{ iconName: 'Edit' }} text='Manage' />
					</Link>
					<CommandButton 
						iconProps={{ iconName: 'Add' }} 
						text='Create New Datasource' 
						onClick={() => {
							setPanelOpen(true);
							setGeneralError('');
						}}
					/>
				</CommandBar>
				<Container>
					<header>
						<h2>{environmentData.name} {/*<StatusLabel color='green'>Deployed</StatusLabel>*/}</h2>
					</header>
					<DWAList data={sortedDatasources} />
				</Container>
				<Panel 
					isOpen={panelOpen} 
					isLightDismiss
					onDismiss={() => setPanelOpen(false)}
					headerText='Create New Datasource'
					isFooterAtBottom={true}
					onRenderFooterContent={() => <>
						<PrimaryButton 
							styles={{ root: { marginRight: 8 } }}
							disabled={isSaving}
							onClick={onCreate}
						>
							Create Datasource
						</PrimaryButton>
						<DefaultButton onClick={() => setPanelOpen(false)}>Cancel</DefaultButton>
					</>}
				>
					{generalError && <MessageBar messageBarType={MessageBarType.error}>{generalError}</MessageBar>}
					<TextField
						autoFocus
						value={newDWA.name}
						onChange={(e, name) => setNewDWA(state => ({ ...state, name: name + '' }))}
						label="Name"
						errorMessage={errors.name}
					/>
					<Dropdown
						label="Type"
						options={datasourceTypeOptions}
						defaultSelectedKey={newDWA.type}
						onChange={(e, newValue) => setNewDWA(state => ({ ...state, type: newValue?.key + '' }))}
						errorMessage={errors.type}
					/>
				</Panel>
			</>}
		</div>
	);

};

export default EnvironmentPage;