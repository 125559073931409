import { DefaultValue } from "recoil";
import { standardHeaders } from "../remote/standardHeaders";

let controllers: { [key: string]: AbortController } = {};

export const fetchRemoteEffect = <T = any>(
  urlComposer: (params: T) => string,
  transformResult = (result: any) => result
) => ({ setSelf, trigger, node, onSet }: any) => {

  const [, paramsStr] = node.key.split('__');
  const params = paramsStr ? JSON.parse(paramsStr) : undefined;
  const url = urlComposer(params);

  const fetchData = async () => {
    if (controllers[url]) {
      controllers[url].abort();
    }
    controllers[url] = new AbortController();
    
    const headers = await standardHeaders();
    const response = await fetch(url, {
      method: 'GET',
      headers,
      mode: 'cors',
      cache: 'no-cache',
      signal: controllers[url].signal
    });

    try {
      if (response.ok) {
        const result = transformResult( await response.json() );
        setSelf(result);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw new Error(error);
      }
    }
  }

  if (trigger === 'get') {
    fetchData();
  }

  onSet((newValue: any) => {
    if (newValue instanceof DefaultValue) {
      fetchData();
    }
  });
}