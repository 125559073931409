import { matchPath } from "react-router-dom";
import { ON_URL_CHANGE } from "../../constants/events";

// CAUTION: UNSTABLE!
// there is no decent way to detect url changes
// https://stackoverflow.com/questions/6390341/how-to-detect-if-url-has-changed-after-hash-in-javascript

export const syncUrlParamsEffect = (path: string, param: string, retainLastTruthyValue = true) => ({ setSelf }: any) => {
  let lastPath = '';

  const changeEvent = () => {
    const currentPath = window.location.pathname;
    if (currentPath !== lastPath) {
      lastPath = currentPath;
      const match = matchPath<{ [key: string]: string }>(currentPath, { path });
      const newValue = match?.params[param] ?? '';
      if (newValue) {
        setSelf(newValue);
      } else if (!retainLastTruthyValue) {
        setSelf('');
      }
    }
  }

  changeEvent();

  window.addEventListener(ON_URL_CHANGE, changeEvent);

  return () => {
    window.removeEventListener(ON_URL_CHANGE, changeEvent);
  }
}
