import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { dwaDatasets } from 'recoil/atoms/dwaDatasets';

import { IDataset } from 'types/IDataset';
import { IDatasetColumnMappings } from 'types/IDatasetColumnMappings';
import { IDatasetMapping } from 'types/IDatasetMapping';
import {
  IDWAConfigDataset,
  IDWAConfigDatasetColumn,
  IDWAConfig,
} from 'types/IDWA'

import { sortDataset } from 'utils/datasetHelper';

export const useReconcileDatasets = (config: Partial<IDWAConfig>,dataSourceDatasets: IDataset[]) => {
  const configDatasets: IDWAConfigDataset[] | undefined = config?.datasets;
  const [output, setOutput] = useRecoilState(dwaDatasets);

  const setColumns = (columnMappings:  Map<string, IDatasetColumnMappings>, datasetStatus: string)
    : IDWAConfigDatasetColumn[] => {
    return (
       Array.from(columnMappings.values())
        .reduce<IDWAConfigDatasetColumn[]>((columns, {fromConfigDataset, fromDataSourceDataset}) => {
          if (fromDataSourceDataset?.name) {
            let columnStatus = 'removed'

            if (fromConfigDataset && fromDataSourceDataset) {
              columnStatus = 'unchanged'
            }
  
            if (!fromConfigDataset && fromDataSourceDataset) {
              columnStatus = 'unmanaged'
            }
  
            if (columnStatus !== 'unchanged' && datasetStatus !== 'removed') {
              datasetStatus = 'changed';
            }
  
            if (columnStatus !== 'unmanaged') {
              fromConfigDataset?.name  && columns.push({
                name: fromConfigDataset!.name!,
                column_type: fromConfigDataset!.column_type!,
                status: columnStatus,
              })
            } 
            else {
              columns.push({
                name: fromDataSourceDataset!.name!,
                column_type: fromDataSourceDataset!.column_type!,
                status: columnStatus,
              })
            }
          }

          return columns;
        }, []
      )
    )
  };

  const setDatasetStatus = (fromConfig: IDWAConfigDataset | undefined, fromDataSource: IDataset | undefined): string => {
    let datasetStatus = 'removed'

    if (fromConfig && fromDataSource) {
      datasetStatus = 'unchanged'
    }
      
    if (!fromConfig && fromDataSource) {
      datasetStatus = 'unmanaged'
    }
      
    return datasetStatus
  }

  const setIntialDWAValue = (datasetStatus: string, fromDataSource: IDataset | undefined ): IDWAConfigDataset =>  (
    {
      id: fromDataSource!.id,
      dataset_id: fromDataSource!.dataset_id,
      name: fromDataSource!.name,
      enabled: true,
      extraction_columns: [],
      extraction_type: 'full',
      load_type: 'addupdatedelete',
      delta_watermark_column: '',
      delta_watermark_offset_type: 'date',
      delta_watermark_offset: 0,
      delete_delta_watermark_column: '',
      delete_delta_watermark_offset_type: 'date',
      delete_delta_watermark_offset: 0,
      business_keys: [],
      change_columns: [],
      status: datasetStatus,
      columns: fromDataSource!.columns!.map((column) => ({
        name: column!.name!,
        column_type: column!.column_type!,
        status: 'unmanaged',
      }))
    } 
  );

  const setDatasets = useCallback((mappings: Map<string, IDatasetMapping>): IDWAConfigDataset[] => {
    return (
      Array.from(mappings.values()).reduce<IDWAConfigDataset[]>(
        (datasets, { fromConfig, fromDataSource }) => {
          const datasetStatus = setDatasetStatus(fromConfig, fromDataSource);

          if (datasetStatus !== 'unmanaged') {
            const columnMappings = new Map<string, IDatasetColumnMappings>();

            const configColumns = fromConfig?.columns ?? [];
            const dataSourceColumns = fromDataSource?.columns ?? [];

            configColumns.forEach((column) => {
              columnMappings.set(column.name, { fromConfigDataset: column });
            });

            dataSourceColumns.forEach((column) => {
              const name = column.name!;

              if (columnMappings.has(name)) {
                columnMappings.set(name, {
                  ...columnMappings.get(name),
                  fromDataSourceDataset: column,
                })
              } else {
                columnMappings.set(name, { fromDataSourceDataset: column })
              }
            });

            const columns = setColumns(columnMappings, datasetStatus);

            datasets.push({
              ...fromConfig!,
              status: datasetStatus,
              columns,
            })
          } else {
            datasets.push({
              ...setIntialDWAValue(datasetStatus, fromDataSource)
            });
          }

          return datasets;
        }, []
      )
    )
  }, []);

  const setMapping = useCallback((managedDatasets: IDWAConfigDataset[], mappings: Map<string, IDatasetMapping>, dataSourceDatasets: IDataset[]) => {
    sortDataset(managedDatasets).forEach((dataset) => {
      mappings.set(dataset.name, { fromConfig: dataset });
    });
  
    sortDataset(dataSourceDatasets).forEach((dataset) => {
      const { name } = dataset;
  
      if (mappings.has(name)) {
        mappings.set(name, {
          ...mappings.get(name),
          fromDataSource: dataset,
        });
      } else {
        mappings.set(name, { fromDataSource: dataset });
      }
    });
  }, []);

  const getStatusCount = (datasets: IDWAConfigDataset[]) => {
    return (
      datasets.reduce((count: any, {status}) => {
        if (status === undefined) {
          return status;
        }

        const setInitialCount = (name: string) => count[name] || 0;

        count['managed'] = setInitialCount('managed');
        count['unmanaged'] = setInitialCount('unmanaged');
        count['removed'] =setInitialCount('removed');
        
        if(status === 'unmanaged' || status === 'removed') {
          count[status] += 1;
        } 
        else {
          count['managed'] += 1;
        }

        return count;
      }, {})
    )
  };
    
  useEffect(() => {
    const mappings = new Map<string, IDatasetMapping>();

    let managedDatasets =  [...configDatasets ?? []];

    setMapping(managedDatasets, mappings, dataSourceDatasets);

    const datasets = setDatasets(mappings);

    const {
      managed: managedCount, 
      unmanaged: unmanagedCount, 
      removed: removedCount
    } = getStatusCount(datasets);
  
    setOutput({ data: datasets, unmanagedCount, managedCount, removedCount });
  
  }, [configDatasets, dataSourceDatasets, setDatasets, setMapping, setOutput]);

  return output;
};
