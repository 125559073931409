import { atomFamily, selector, SerializableParam } from 'recoil';
import { IEnvironment } from '../../types/IEnvironment';
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { noopPromise } from "./noopPromise";

export type IEnvironmentsParam = SerializableParam & {
	endpoint: string;
	solutionId: string;
}

export const environmentsAtom = atomFamily<IEnvironment[], IEnvironmentsParam>({
	key: 'environments',
	default: noopPromise([]),
	effects_UNSTABLE: [
		fetchRemoteEffect<IEnvironmentsParam>(({endpoint, solutionId}) => `${endpoint}/${solutionId}/environments`)
	]
});

export const environments = selector<IEnvironment[]>({
	key: 'environments/proxy',
	get: async ({ get }) => {
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);

		if (endpoint) {
			return get(environmentsAtom({ endpoint, solutionId }));
		}
		
		return [];
	}
});