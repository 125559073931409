import { selector } from 'recoil';

import { dwaDatasets } from 'recoil/atoms/dwaDatasets';

import { isDatasetValid } from 'components/utils/DWAS2MHelper';

interface IDWADatasetsConflictCount {
	managedConflicts: number;
	unmanagedConflicts: number;
}

export const dwaDatasetsConflictCount = selector<IDWADatasetsConflictCount> ({
	key: 'dwaDatasetsConflictCount',
	get: ({get}) => {
		const { data } = get(dwaDatasets);

		return data.reduce((count, dataset,) => {
			if (!isDatasetValid(dataset)) { 
				if (dataset?.status === 'unmanaged') 
					return {...count, unmanagedConflicts: count.unmanagedConflicts + 1};

				return {...count, managedConflicts: count.managedConflicts + 1};
			};

			return count;
		}, { managedConflicts: 0, unmanagedConflicts: 0 });
	}
});