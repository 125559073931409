import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { selectedDwaId } from "../recoil/atoms/selectedDwaId";
import { selectedEnvironmentId } from "../recoil/atoms/selectedEnvironmentId";
import { selectedJobLogsId } from "../recoil/atoms/selectedJobLogs";
import { selectedSolutionId } from "../recoil/atoms/selectedSolutionId";
import { dwaSelectedDataset } from 'recoil/atoms/dwaSelectedDataset';
import { dwa } from 'recoil/remote/dwa';
import { apiEndpoint } from "../recoil/remote/apiEndpoint";

export const useSelectedParams = () => {
  const endpoint      = useRecoilValue(apiEndpoint);
  const solutionId    = useRecoilValue(selectedSolutionId);
  const environmentId = useRecoilValue(selectedEnvironmentId);
  const dwaId         = useRecoilValue(selectedDwaId);
  const jobId        = useRecoilValue(selectedJobLogsId);
  const dwaData         = useRecoilValue(dwa);
  const dataset        = useRecoilValue(dwaSelectedDataset);
  
  return useMemo(() => {
    return {
      endpoint: endpoint ?? '',
      solutionId: solutionId ?? '',
      environmentId: environmentId ?? '',
      dwaId: dwaId ?? '',
      jobId: jobId ?? '',
      datasourceId: dwaData?.de_config.datasource_id ?? '',
      datasetId: dataset?.dataset_id ?? '',
    }
  }, [endpoint, solutionId, environmentId, dwaId, jobId, dwaData, dataset]);
}