import { selector } from 'recoil';
import { dwaDatasets } from 'recoil/atoms/dwaDatasets';
import { dwaDatasetFilters } from 'recoil/atoms/dwaDatasetFilters';

import { IDWAConfigDataset } from 'types/IDWA';
import { ITypeValues } from 'types/ITypeValues';

export const dwaFilteredDatasets = selector({
	key: 'dwaFilteredDatasets',
	get: ({ get }) => {
		const datasets = get(dwaDatasets);
		const {
			search, 
			status: datasetStatus, 
			selectedTypes, 
			showModified 
		} = get(dwaDatasetFilters);

		const filterBySearchValue = (name: string, search: string, status: string | undefined) => {
			if (name.toUpperCase().includes(search.toUpperCase())) {
				if (datasetStatus === 'managed') {
					return ['changed', 'unchanged'].some((value) => status === value);
				} 			
					
				return status === datasetStatus;
			}

			return false
		}

		const filterBySelectedTypes = (data: IDWAConfigDataset, selectedTypes: ITypeValues) => {
			const { name, status, extraction_type, load_type } = data;
			const { extraction_type: selectedExtraction, load_type: selectedLoad } = selectedTypes;

			if (extraction_type === selectedExtraction && load_type === selectedLoad) {
				return filterBySearchValue(name, search, status)
			}

			return false;
		}
		
		const filterByModifiedValue = (data: IDWAConfigDataset) =>{
			const { modified, name, status } = data;

			if (typeof showModified !== 'undefined') {
				if ((showModified && !modified) || (!showModified && modified)) {
					return false;
				}
			}

			return filterBySearchValue(name, search, status);
		}

		if (datasetStatus !== 'managed') {
			return datasets.data.filter(({ name, status }) => {
				return filterBySearchValue(name, search, status)
			});
		}

		return datasets.data.filter((data) => {
			if (selectedTypes) {
				return filterBySelectedTypes(data, selectedTypes)
			}

			return filterByModifiedValue(data)
		});
	}
});