import React, { useEffect, useRef, useState } from 'react';
import './DocumentationPage.css';

const DocumentationPage: React.FC = () => {

	const [path] = useState(window.location.pathname.replace('/docs', '/_docs').replace(/\/$/, ''));
	const iframeRef = useRef<HTMLIFrameElement>(null);

	useEffect(() => {

		const iframe = iframeRef.current;

		const onLoad = () => {
			if (iframe?.contentWindow) {
				const path = iframe.contentWindow.location.pathname.replace('/_docs', '/docs').replace(/\/$/, '');
				if (path !== window.location.pathname.replace(/\/$/, '')) {
					window.history.replaceState(null, 'LakeFlow', path);
				}
			}
		}

		if (iframe) {
			iframe.addEventListener('load', onLoad);
		}

		return () => {
			iframe?.removeEventListener('load', onLoad);
		}
	}, []);

	return (
		<iframe title='Lakeflow | Documentation' ref={iframeRef} className='da-docs-iframe' src={`${process.env.PUBLIC_URL}${path}`} />
	);
}

export default DocumentationPage;