import { standardHeaders } from "./standardHeaders";

export const publishDwa = async (apiEndpoint: string, solutionId: string, environmentId: string, dwaId: string, config: string, jobType: string) => {
	const response = await fetch(`${apiEndpoint}/${solutionId}/environments/${environmentId}/dwas/${dwaId}/publish?job_type=${jobType}`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: config
	});
	
	return response;
}
