import React from 'react';
import './AccessDenied.css';

const AccessDenied: React.FC = () => {
	return (
		<div className='da-access-denied'>
			<img src="./logo-small.png" alt="DataAddiction" />
			<p>Please contact Data Addiction</p>
		</div>
	);
};

export default AccessDenied;