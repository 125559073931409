import { atomFamily, selector, SerializableParam } from 'recoil';
import { IDWA } from '../../types/IDWA';
import { dwaTemplateIdMappings } from '../../enums/dwaTemplateIdMappings';
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { noopPromise } from "./noopPromise";
import { selectedDwaId } from "../atoms/selectedDwaId";
import { apiEndpoint } from "./apiEndpoint";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";

export type IDWAParams = SerializableParam & {
	endpoint: string;
	solutionId: string;
	environmentId: string;
	dwaId: string;
}

export const dwaAtom = atomFamily<IDWA | null, IDWAParams>({
	key: 'dwa',
	default: noopPromise(null),
	effects_UNSTABLE:[
		fetchRemoteEffect<IDWAParams>(
			({ endpoint, solutionId, environmentId, dwaId }) => 
				`${endpoint}/${solutionId}/environments/${environmentId}/dwas/${dwaId}`,
			(result) => {
				const type = dwaTemplateIdMappings[result.dwa_template_id.toLowerCase()];
				return {
					...result,
					type
				}
			}
		)	
	]
});

export const dwa = selector<IDWA | null>({
	key: 'dwa/proxy',
	get: async ({ get }) => {
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		const dwaId = get(selectedDwaId);
		
		if (endpoint && environmentId && dwaId) {
			return get(dwaAtom({ endpoint, solutionId, environmentId,dwaId }));
		}

		return null;
	}
});