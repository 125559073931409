import { IColumn } from "@fluentui/react";

export const unmanagedDatasetFields: IColumn[] = [
  {
    key: "name",
    fieldName: "name",
    name: "Dataset Name",
    minWidth: 200,
  },
  {
    key: "actions",
    fieldName: "actions",
    name: "Actions",
    minWidth: 600,
  },
];

export const managedDatasetFields: IColumn[] = [
  {
    key: "name",
    fieldName: "name",
    name: "Dataset Name",
    minWidth: 200,
  },
  {
    key: "type",
    fieldName: "type",
    name: "Type",
    minWidth: 500,
  },
  {
    key: "enabled",
    fieldName: "enabled",
    name: "Enabled",
    minWidth: 100,
  },
];
