import { selector } from "recoil";
import { IJob } from "../../types/IJob";
import { selectedJobLogsId } from "../atoms/selectedJobLogs";
import { jobs } from "./jobs";

export const job = selector<IJob | null>({
  key: "job/proxy",
  get: async ({ get }) => {
    
    const jobId = get(selectedJobLogsId);
    const jobsData = get(jobs);

    return jobsData.find(job => job.id === jobId) ?? null;
  }
});

