import React, { useState } from 'react';
import { AzureAD, AuthenticationState, IAzureADFunctionProps, LoginType, MsalAuthProvider } from 'react-aad-msal';
import Splash from "../components/common/Splash";

export const authProvider = new MsalAuthProvider(
	{
		auth: {
			authority: 'https://login.microsoftonline.com/organizations',
			clientId: 'd209f57d-e6ee-4711-8bc5-1d5cd05e8fb1',
			postLogoutRedirectUri: window.location.origin,
			redirectUri: window.location.origin,
			validateAuthority: true,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: true
		}
	},
	{
		scopes: ['openid']
	},
	{
		loginType: LoginType.Redirect,
		// tokenRefreshUri: process.env.PUBLIC_URL + '/auth.html'
	}
);

export const context = React.createContext<IAzureADFunctionProps>({ 
	accountInfo: null,
	authenticationState: AuthenticationState.Unauthenticated,
	error: null,
	login: () => {},
	logout: () => {}
});

const AuthProvider: React.FC = ({ children }) => {
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	return (
		<AzureAD provider={authProvider}>
			{(props: IAzureADFunctionProps) => {

				if (props.authenticationState === AuthenticationState.Unauthenticated && !isLoggingIn) {
					setIsLoggingIn(true);
					props.login();
				}

				if (props.authenticationState !== AuthenticationState.Authenticated) {
					return (
						<Splash />
					);
				} else {
					setIsLoggingIn(false);
				}

				return (
					<context.Provider value={props}>
						{children}
					</context.Provider>
				);
			}}
		</AzureAD>
	);
}

export default AuthProvider;