import { atom } from 'recoil';
import { localStorageEffect } from "../effects/localStorageEffect";

import  { ITypeValues } from 'types/ITypeValues';

interface IDWADatasetFilters {
	search: string;
	status: string;
	selectedTypes?: ITypeValues
	showModified?: boolean;
}

export const dwaDatasetFilters = atom<IDWADatasetFilters>({
	key: 'dwaDatasetFilters',
	default: {
		search: '',
		status: 'settings',
		selectedTypes: undefined,
		showModified: undefined
	},
	effects_UNSTABLE: [
		localStorageEffect // ('dwaDatasetFilters')
	]
});