import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { IBreadcrumbItem } from '@fluentui/react';

import { datasource } from 'recoil/remote/datasource';
import { dwa } from 'recoil/remote/dwa';
import { environment } from 'recoil/remote/environment';

import { breadcrumbsAtom } from 'recoil/atoms/breadcrumbs';
import { dwaConfigSettings } from 'recoil/atoms/dwaConfigSettings';
import { selectedEnvironmentId } from 'recoil/atoms/selectedEnvironmentId';
import { selectedSolutionId } from 'recoil/atoms/selectedSolutionId';

import DWAS2MPage from './s2m/common/DWAS2MPage';
import DWAStandUpPage from './s2m/standup/DWAStandUpPage';

import './DWAPage.css';

export const DWAPage: React.FC = () => {
	const history         = useHistory();
	const solutionId      = useRecoilValue(selectedSolutionId);
	const environmentId   = useRecoilValue(selectedEnvironmentId);
	const environmentData = useRecoilValue(environment);
	const dwaData         = useRecoilValue(dwa);
	const datasourceData  = useRecoilValue(datasource);
	const setBreadcrumbs  = useSetRecoilState(breadcrumbsAtom);
	const setConfig       = useSetRecoilState(dwaConfigSettings);

	useEffect(() => {
		if (dwaData) setConfig(dwaData.de_config);
	}, [dwaData, setConfig]);

	useEffect(() => {
		const breadcrumbs: IBreadcrumbItem[] = [
			{ text: 'Manage', key: 'Manage', onClick: () => history.push('/manage') }
		];
		
		breadcrumbs[1] = {
			text: environmentData?.name ?? '',
			key: 'Environment',
			onClick: () => history.push(`/solution/${solutionId}/environment/${environmentId}`)
		};

		breadcrumbs[2] = {
			text: datasourceData?.name ?? (dwaData?.type === 'env_standup' ? 'Environment Standup' : 'Unknown DWA'),
			key: 'DWA Environment'
		};
		
		setBreadcrumbs(breadcrumbs);
	}, [setBreadcrumbs, history, solutionId, environmentId, environmentData, dwaData, datasourceData]);

	const SQLTypes = ['s2m_sql', 's2m_d365'];

	return (
		<div className='da-dwa-page'>
			{SQLTypes.some(type => dwaData?.type === type ) && <DWAS2MPage type={'SQL'} />}
			{dwaData?.type === 's2m_blob' && <DWAS2MPage type={'Blob'} />}
			{dwaData?.type === 'env_standup' && <DWAStandUpPage />}
		</div>
	);
};

export default DWAPage;