import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import { ON_URL_CHANGE } from "../../constants/events";

export const UrlListener: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.dispatchEvent(new Event(ON_URL_CHANGE));
  }, [location.pathname]);
  return null;
}