import React from 'react';
import { IDetailsListProps, DetailsRow, IDetailsRowStyles } from '@fluentui/react'
import { IDWAConfigDataset } from "../../../../../types/IDWA";

export const DWAS2MRowRenderer: React.FC<IDetailsListProps['onRenderRow'] | any> = (props) => {
	const style: Partial<IDetailsRowStyles> = {
		root: {
			backgroundColor: (props?.item as IDWAConfigDataset)?.modified ? 'var(--themeLighterAlt)' : 'var(--white)',
			opacity: (props?.item as IDWAConfigDataset)?.status === 'removed' ? 0.5 : 1
		}
	};
	return (
		<DetailsRow {...props} styles={style} />
	);
};