import React from 'react';
import { Link } from 'react-router-dom';
import { FontIcon } from '@fluentui/react';
import { IEnvironmentCardProps } from "../../../types/IEnvironmentCardProps";
import './EnvironmentCard.css';

const getIconName = (name: string) => {
	if (name.toLowerCase().includes('dev')) {
		return 'ExternalBuild';
	}
	if (name.toLowerCase().includes('uat') || name.toLowerCase().includes('test')) {
		return 'TestPlan';
	}
	if (name.toLowerCase().includes('prod')) {
		return 'BankSolid';
	}
	return 'ServerEnviroment';
}

export const EnvironmentCard: React.FC<IEnvironmentCardProps> = ({ solutionId, data }) => {
	const iconName = getIconName(data.name);

	return (
		<section className='da-environment-card'>
			<Link to={`/solution/${solutionId}/environment/${data.id}`}>
				<header>
					<FontIcon iconName={iconName} />{data.name}
				</header>
			</Link>
			<div className='da-environment-card-details'>
				{/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
				<p>Nam soluta beatae, blanditiis est odit totam possimus perferendis veniam ad quos impedit</p> */}
			</div>
		</section>
	);
}
