import {
    IDWAConfigDataset,
    IDWAConfigDatasetColumn,
} from 'types/IDWA';
import { ITypeValues } from 'types/ITypeValues';

import { typeValues } from 'enums/typeOptions';

interface IColumnOptions {
	name: string;
	column_type: string;
}

export const areBusinessKeysVisible = (extraction_type: string | undefined, loadType: string | undefined): boolean => {
    let isVisible = false;

    if ((extraction_type === 'delta' &&  ['addupdate', 'addupdatedelete'].some(type => type === loadType)) || 
    (extraction_type === 'full' && loadType === 'addupdate')) {
        isVisible = true;
    }
        
    return isVisible
}

export const areWatermarkFieldsVisible = (extractionType: string | undefined): boolean => {
    return !extractionType ? false : extractionType === 'delta';
}

export const areDeleteWatermarkFieldsVisible = (extraction_type: string | undefined, loadType: string | undefined): boolean => {
    return extraction_type === 'delta' && loadType === 'addupdatedelete'
}

export const isEnabledVisible = (status: string | undefined): boolean => {
    return !status ? false : status !== 'unmanaged';
}

export const getColumnOptions = (columns: IDWAConfigDatasetColumn[]) => {
    return [...columns].sort(({name: currentName}, {name: nextName}) => {
        return sortArrayValue(currentName, nextName)
    })
    .filter(({name}) => name)
    .map(({name, column_type}: IColumnOptions) => {
        return ({ key: name, text: `${name} (${column_type})` })
    })
}

export const getBusinessKeys = (business_keys: any[], item: any): any[] => {
    const { key: itemKey, selected} = item;
    
    return selected ? 
        [...business_keys, itemKey] : 
        [...business_keys].filter(key => itemKey !== key);
}

export const getSelectedTypeValues = (typeKey: string): ITypeValues | null => {
    const selectedTypeValue = typeValues.find(({key}) => key === typeKey);
    
    if (!selectedTypeValue) return null;
		
	const { extraction_type, load_type } = selectedTypeValue.values;

    return { extraction_type, load_type };
}

export const getSelectedType = (dataset: IDWAConfigDataset | null): string => {
    if(!dataset) return '';

    const {extraction_type, load_type} = dataset;

    return [extraction_type, load_type].every(Boolean) ? 
        extraction_type + load_type : '';
}

export const isDatasetValid = (dataset: IDWAConfigDataset | null): boolean => {
    if(!dataset) return true;

    const {
        business_keys, 
        delete_delta_watermark_column, 
        delta_watermark_column,
        extraction_type,
        load_type,
    } = dataset;

    let fields: any[] = [];

    if (areBusinessKeysVisible(extraction_type, load_type)){
        fields = [...fields, business_keys.length > 0]
    }

    if (areWatermarkFieldsVisible(extraction_type)){
        fields = [...fields, delta_watermark_column]
    }

    if (areDeleteWatermarkFieldsVisible(extraction_type, load_type)){
        fields = [...fields, delete_delta_watermark_column]
    }

    return fields.every(field => field);
}

export const sortArrayValue = (current: string, next: string) => {
    if (current < next) 
        return -1;

    if (current > next)  
        return 1;

    return 0;
}

export const isFieldDisabled = (status: string | undefined, isUpdating: boolean): boolean => {
    return status === 'removed' || isUpdating;
}
    