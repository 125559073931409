import { atom } from 'recoil';
import { IReconcileDatasets } from '../../types/IReconcileDatasets';

export const dwaDatasets = atom<IReconcileDatasets>({
	key: 'dwaDatasets',
	default: {
		data: [],
		managedCount: 0,
		unmanagedCount: 0,
		removedCount: 0
	}
});