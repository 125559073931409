import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	Panel,
	PanelType,
	Pivot,
	PivotItem,
	DetailsList,
	SelectionMode,
} from '@fluentui/react';

import { dwaSelectedDataset } from 'recoil/atoms/dwaSelectedDataset';
import { dwaActiveTab } from 'recoil/selectors/dwaActiveTab';

import { datasetColumnFields } from 'enums/datasetColumnFields';

import { DWAS2MPanelForm } from './DWAS2MPanelForm';
import { DWAS2MWatermarks } from './DWAS2MWatermarks';

import './DWAS2MDatasetDrilldown.css';

export const DWAS2MDatasetDrilldown: React.FC = () => {
	const activeTab = useRecoilValue(dwaActiveTab);

	const [selectedDataset, setSelectedDataset] = useRecoilState(dwaSelectedDataset);
	
	const [activeDatasetTab, setActiveDatasetTab] = useState('columns');

	const isTabManaged = activeTab === 'managed';

	return (
		<Panel
			type={PanelType.custom}
			customWidth='680px'
			isOpen={!!selectedDataset}
			onDismiss={() => setSelectedDataset(null)}
			isLightDismiss={true}
			headerText={`${selectedDataset?.name} (${selectedDataset?.status})`}
		>
			<Pivot
				selectedKey={activeDatasetTab}
				onLinkClick={(item) => setActiveDatasetTab(item!.props.itemKey!)}
			>
				<PivotItem headerText='Columns' itemKey='columns'>
					<DetailsList
						columns={datasetColumnFields}
						items={selectedDataset?.columns ?? []}
						selectionMode={SelectionMode.none}
					/>
				</PivotItem>
				{isTabManaged && (
					<PivotItem headerText='Settings' itemKey='settings'>
						<DWAS2MPanelForm />
					</PivotItem>
				)}
				{isTabManaged && (
					<PivotItem headerText='Watermark' itemKey='watermark'>
						<DWAS2MWatermarks />
					</PivotItem>
				)}
			</Pivot>
		</Panel>
	)
};