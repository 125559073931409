import React from 'react';
import { useRecoilValue } from 'recoil';
import { DefaultButton } from '@fluentui/react';

import { dwaUpdateStatus } from 'recoil/atoms/dwaUpdateStatus';

import { dwaSelectedDatasetsCount } from 'recoil/selectors/dwaSelectedDatasetsCount';
import { dwaActiveTab } from 'recoil/selectors/dwaActiveTab';

import { useLocalUpdateDatasets } from 'hooks/useLocalUpdateDatasets';

import './DWAS2MBatchControl.css';

export const DWAS2MBatchControl: React.FC = () => {
	const updateDatasets = useLocalUpdateDatasets();

	const activeTab = useRecoilValue(dwaActiveTab);
	const selectedDatasetsCount = useRecoilValue(dwaSelectedDatasetsCount);
	const updateStatus = useRecoilValue(dwaUpdateStatus);

	const isDisabled = selectedDatasetsCount < 1 || updateStatus.isUpdating;

	const buttonStyles: React.CSSProperties = {
		borderColor: `var(--${isDisabled ? 'neutralLighter' : 'themePrimary'})`,
		color: `var(--${isDisabled ? 'neutralTertiary' : 'themePrimary'})`,
	}

	return (
		<div className='da-dwa-batch-control'>
			<span>
				{selectedDatasetsCount} dataset{selectedDatasetsCount > 1 ? 's ' : ' '} 
				selected:
			</span>
			
			{activeTab === 'managed' && 
				<>
					<DefaultButton
						text='Enable'
						onClick={() => updateDatasets({ enabled: true })}
						disabled={isDisabled}
					/>
					<DefaultButton
						text='Disable'
						onClick={() => updateDatasets({ enabled: false })}
						disabled={isDisabled}
					/>
				</>
			}

			{activeTab === 'unmanaged' && 
				<DefaultButton
					text='Add to Managed'
					style={buttonStyles}
					onClick={() => updateDatasets({ status: 'changed' })}
					disabled={isDisabled}
				/>
			}
		</div>
	)
}