import { selector } from 'recoil';
import { dwaDatasetFilters } from '../atoms/dwaDatasetFilters';

export const dwaSearch = selector<any>({
	key: 'dwaSearch',
	get: ({get}) => {
		const datasetFilter = get(dwaDatasetFilters);
		return datasetFilter.search;
	},
	set: ({get, set}, newValue) => {
		const datasetFilter = get(dwaDatasetFilters);
		set(dwaDatasetFilters, {...datasetFilter, search: newValue});
	} 
});