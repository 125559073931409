import React from 'react';
import { useRecoilState } from 'recoil';
import { Dropdown, TextField } from '@fluentui/react';

import { dwaConfigSettings } from 'recoil/atoms/dwaConfigSettings';

import { s2mBlobSourceFileTypeOptions } from 'enums/s2mBlobSourceFileTypeOptions';
import { s2mBlobSourceDatasetTypeOptions } from 'enums/s2mBlobSourceDatasetTypeOptions';
import { s2mVersionOptions } from 'enums/s2mVersionOptions';

export const BlobSettings: React.FC = () => {
	const [config, setConfig] = useRecoilState(dwaConfigSettings);

	return (
		<div className='da-panel'>
			<form>
			    <Dropdown
					label='Source Dataset Type'
					selectedKey={config.source_dataset_type}
					options={s2mBlobSourceDatasetTypeOptions}
					onChange={(_, item) =>
						setConfig((state) => ({
							...state,
							source_dataset_type: item?.key + '',
						}))
					}
                />

				<Dropdown
					label='Version'
					selectedKey={config.version}
					options={s2mVersionOptions}
					onChange={(_, item) =>
						setConfig((state) => ({
						...state,
						version: item?.key + '',
						}))
					}
				/>

				{config.source_dataset_type !== "API" &&
				<>
					<TextField
						label='Landing Zone Storage Account'
						value={config.source_storage_account}
						onChange={(e, newValue) => 
							setConfig(state => ({ 
								...state, 
								source_storage_account: newValue!, 
							}))
						}
					/>
					<TextField
						label='Blob begins with'
						value={config.source_blob_path_begins_with}
						onChange={(e, newValue) => 
							setConfig(state => ({ 
								...state, 
								source_blob_path_begins_with: newValue!
							}))
						}
					/>
					<TextField
						label='Blob ends with'
						value={config.source_blob_path_ends_with}
						onChange={(e, newValue) => 
							setConfig(state => ({ 
								...state, 
								source_blob_path_ends_with: newValue! 
							}))
						}
					/>
					<Dropdown
						label='Source Blob File Type'
						selectedKey={config.source_blob_file_type}
						options={s2mBlobSourceFileTypeOptions}
						onChange={(_, item) =>
							setConfig((state) => ({
								...state,
								source_blob_file_type: item?.key + '',
							}))
						}
					/>
					{config.source_blob_file_type==='CSV' &&
						<TextField
							label='CSV - Delimiter'
							value={config.source_blob_csv_delimiter}
							onChange={(e, newValue) => 
								setConfig(state => ({ 
									...state, 
									source_blob_csv_delimiter: newValue!
								}))
							}
						/>
					}
					{config.source_blob_file_type==='JSON' &&
						<TextField
							label='JSON - JSONPath'
							value={config.source_blob_json_jsonpath}
							onChange={(e, newValue) => 
								setConfig(state => ({ 
									...state, 
									source_blob_json_jsonpath: newValue! 
								}))
							}
						/>
					}
				</>
				}
				<TextField
					label='Data Source ID'
					readOnly
					defaultValue={config?.datasource_id}
				/>
				<TextField
					label='DWA Environment ID'
					readOnly
					defaultValue={config?.dwa_environment_id}
				/>
				<TextField 
					label='DWA API Key' 
					readOnly 
					defaultValue={config.dwa_environment_api_key} 
				/>
			</form>
		</div>
	);
};