import { atomFamily, selector, SerializableParam } from "recoil";
import { dwa } from "./dwa";
import { IDataset } from "../../types/IDataset";
import { noopPromise } from "./noopPromise";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";

export type IDatasetsParam = SerializableParam & {
  solutionId: string;
  environmentId: string;
  datasourceId?: string;
};

export const datasetsAtom = atomFamily<IDataset[], IDatasetsParam>({
  key: "datasets",
  default: noopPromise([]),
  effects_UNSTABLE: [
    fetchRemoteEffect(({endpoint, solutionId, environmentId, datasourceId}) => 
      `${endpoint}/${solutionId}/environments/${environmentId}/datasources/${datasourceId}/datasets`)
  ]
});

export const datasets = selector<IDataset[]>({
  key: "datasets/proxy",
  get: async ({ get }) => {
    const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
    const dwaData = get(dwa);

    if (solutionId && environmentId && dwaData?.de_config.datasource_id) {
      return get(datasetsAtom({ endpoint, solutionId, environmentId, datasourceId: dwaData?.de_config.datasource_id }));
    }

    return [];
  },
});
