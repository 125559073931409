import React, { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { 
    DetailsList, 
    IColumn, 
    IconButton, 
    MessageBar, 
    PrimaryButton, 
    SelectionMode,
    Separator,
    Text, 
    TextField, 
} from '@fluentui/react';

import { createWatermark, watermarksAtom } from 'recoil/remote/watermarks';

import { useSelectedParams } from "hooks/useSelectedParams";

import { watermarksColumnFields } from 'enums/watermarksColumnFields';

import './DWAS2MWatermarks.css';

const WatermarksList = ({ items, onDelete} : {items : any[], onDelete: any}) => {
    const watermarks = [...items].reverse();
    const [hover, setHover] = useState(false);

    return (
        <>  
            <DetailsList
                columns={watermarksColumnFields}
                items={watermarks}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={(item: any, index: number| undefined, column: IColumn| undefined) => {
                    

                    const buttonTitle = "Active";

                    const anchorStyles: React.CSSProperties = {
                        color: '#323130',
                        cursor: 'pointer',
                        fontWeight: 'bolder',
                        textDecoration: 'underline',
                        ...(hover && { textDecoration: 'underline'}),
                    };

                    const mouseEvent = {
                        onMouseEnter: () => setHover(true),
                        onMouseLeave: () => setHover(false),
                    };

                    switch (column?.key) {
                        case 'ts':
                            return ( <span>{item?.ts}</span> );
                        case 'watermark':
                            return ( <span>{item?.watermark}</span> );
                        case 'active':
                            return ( item?.active && ( <IconButton
                                ariaLabel={buttonTitle}
                                iconProps={{ iconName: "Completed"}}
                                title={buttonTitle}
                            /> ) );
                        case 'actions':
                            return ( (index === 0 && item.active) && 
                                <span 
                                    {...mouseEvent}
                                    onClick={onDelete} 
                                    style={anchorStyles}
                                >
                                    Remove
                                </span> 
                            );
                        default:
                            return;
                    }
                  }}
            />
            {(items.length === 0) && <Text className="da-form-text">No data available.</Text>}
        </>
    );
};

export const DWAS2MWatermarks: React.FC = () => {
    const [watermarkValue, setWatermarkValue] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const params = useSelectedParams();
    const {endpoint, solutionId, environmentId, datasourceId, datasetId} = params;
    

    let selectedWatermarks = useRecoilState(watermarksAtom({ ...params }));
    const watermarksList = selectedWatermarks?.[0] ?? []

    const refreshWatermarks   = useResetRecoilState(watermarksAtom({ ...params }));

    const onSave = async () => {
        setIsSaving(true);
        const response = await createWatermark(endpoint, solutionId, environmentId, datasourceId, datasetId, watermarkValue);

        if (response.ok) {
            refreshWatermarks();
		} else {
			setErrorMsg('Unable to create a new watermark. Please try again later.');
		}

		setIsSaving(false);
    }

    const onDelete = async () => {
        setIsSaving(true);
        const response = await createWatermark(endpoint, solutionId, environmentId, datasourceId, datasetId);

        if (response.ok) {
            refreshWatermarks();
		} else {
			setErrorMsg('Unable to create a new watermark. Please try again later.');
		}

		setIsSaving(false);
    }

    return (
        <>
            {errorMsg && <MessageBar messageBarType={1}>{errorMsg}</MessageBar>}
            <form className='da-watermark-form'>
                <TextField
                    label='Watermark *'
                    onChange={(e, newValue) => 
						setWatermarkValue(newValue ?? '')
					}
                />
                <PrimaryButton 
                    className='da-watermark-btn'
                    text='Add'
                    disabled={isSaving || !watermarkValue}
                    onClick={onSave}
                />
            </form>
            <WatermarksList 
                items={watermarksList ?? null}
                onDelete={onDelete}
            />
        </>
    );
};
