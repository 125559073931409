import { IColumn } from '@fluentui/react';

export const datasetColumnFields: IColumn[] = [
	{
		key: 'name',
		fieldName: 'name',
		name: 'Name',
		minWidth: 110
	},
	{
		key: 'column_type',
		fieldName: 'column_type',
		name: 'Column Type',
		minWidth: 110
	},
	{
		key: 'status',
		fieldName: 'status',
		name: 'Status',
		minWidth: 110
	},
];
