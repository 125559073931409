export const jobLogsColumnFields = [
	{
		key: "id",
		name: "ID",
		fieldName: "id",
		minWidth: 100,
		isResizable: true,
	},
	{
		key: "lastUpdated",
		name: "Last Updated",
		fieldName: "lastUpdated",
		minWidth: 150
	},
	{
		key: "timeSince",
		name: "Time Since",
		fieldName: "timeSince",
		minWidth: 120
		
	},
	{
		key: "statusText",
		name: "Status",
		fieldName: "statusText",
		minWidth: 120
	},
	// {
	// 	key: "logsCount",
	// 	name: "Logs Count",
	// 	fieldName: "logsCount",
	// 	minWidth: 80,
	// 	maxWidth: 80,
	// },
	// {
	// 	key: "logsFirst",
	// 	name: "Logs First",
	// 	fieldName: "logsFirst",
	// 	minWidth: 100,
	// 	maxWidth: 200,
	// },
];