import { atomFamily, selector, SerializableParam } from "recoil";
import moment from "moment";
import { IJob, IJobRemote } from "../../types/IJob";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { noopPromise } from "./noopPromise";
import { apiEndpoint } from "./apiEndpoint";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";
import { selectedDwaId } from "../atoms/selectedDwaId";
import { JOB_COMPLETE, JOB_ERRORED, JOB_IN_PROGRESS, JOB_NOT_STARTED } from "../../constants/jobStatus";

export type IJobsParam = SerializableParam & {
  endpoint: string;
  solutionId: string;
  environmentId: string;
  dwaId: string;
};

export const refreshId = ({ solutionId, environmentId, dwaId }: Partial<IJobsParam>) => {
  return `jobs/${solutionId}/${environmentId}/${dwaId}`;
}

export const jobsAtom = atomFamily<IJob[], IJobsParam>({
  key: "jobs",
  default: noopPromise([]),
  effects_UNSTABLE:[
		fetchRemoteEffect<IJobsParam>(
			({ endpoint, solutionId, environmentId, dwaId }) => 
				`${endpoint}/${solutionId}/environments/${environmentId}/dwas/${dwaId}/jobs`,
			(result) => {
				const jobsData =
          (result.map((job: IJobRemote) => {
            let date = moment.utc(job.timestamp).local();
            let statusText = "";

            if (job!.status === "0") {
              statusText = JOB_NOT_STARTED;
            } else if (job!.status === "1") {
              statusText = JOB_IN_PROGRESS;
            } else if (job!.status === "2") {
              statusText = JOB_COMPLETE;
            } else if (job!.status === "-1") {
              statusText = JOB_ERRORED;
            }

            return {
              id: job.id,
              sortDate: date.valueOf(),
              lastUpdated: date.format("D MMM YYYY, h:mm:ssa"),
              timeSince: date.fromNow(),
              statusText: statusText,
            };
          }) as IJob[]).sort((a, b) => b.sortDate - a.sortDate) ?? [];

        return jobsData;
			}
		)	
	]
});

export const jobs = selector<IJob[]>({
  key: "jobs/proxy",
  get: async ({ get }) => {
    const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		const dwaId = get(selectedDwaId);

    if (endpoint && environmentId && dwaId) {  
      return get(jobsAtom({ endpoint, solutionId, environmentId, dwaId }));
    }

    return [];
  }
});

