import { DefaultValue } from "recoil";

// CAUTION: UNSTABLE!
// https://recoiljs.org/docs/guides/atom-effects/#local-storage-persistence
// https://recoiljs.org/docs/guides/atom-effects/#browser-url-history-persistence

export const localStorageEffect = ({setSelf, onSet, node}: any) => {
  const savedValue = localStorage.getItem(node.key)
  if (savedValue !== null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any) => {
    if (newValue instanceof DefaultValue) {
      localStorage.removeItem(node.key);
    } else {
      localStorage.setItem(node.key, JSON.stringify(newValue));
    }
  });
};