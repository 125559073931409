import React from 'react';
import { useRecoilValue } from 'recoil';

import { Container } from 'components/common/Container';

import { DWAS2MColumn } from 'components/pages/dwa/s2m/common/DWAS2MColumn';
import { DWAS2MCommandBar } from 'components/pages/dwa/s2m/common/DWAS2MCommandBar';
import { DWAS2MDatasetDrilldown } from 'components/pages/dwa/s2m/common/DWAS2MDatasetDrilldown';
import { DWAS2MGrid } from 'components/pages/dwa/s2m/common/DWAS2MGrid';
import { DWAS2MListOptions } from 'components/pages/dwa/s2m/common/DWAS2MListOptions';
import { DWAS2MRowRenderer } from 'components/pages/dwa/s2m/common/DWAS2MRowRenderer';
import { DWAS2MTabs } from 'components/pages/dwa/s2m/common/DWAS2MTabs';

import { JobsTab } from 'components/pages/dwa/s2m/common/jobs/JobsTab';

import { BlobSettings } from '../DWAS2MSettings/BlobSettings';
import { SQLSettings } from '../DWAS2MSettings/SQLSettings';


import { useReconcileDatasets } from 'hooks/useReconcileDatasets';

import { datasets } from 'recoil/remote/datasets';
import { dwaActiveTab } from 'recoil/selectors/dwaActiveTab';
import { dwaConfigSettings } from 'recoil/atoms/dwaConfigSettings';

import {
  unmanagedDatasetFields as unmanagedSQLFields,
  managedDatasetFields as managedSQLFields,
} from 'enums/s2mSQLDatasetFields';

import {
  unmanagedDatasetFields as unmanagedBlobFields,
  managedDatasetFields  as managedBlobFields,
} from "enums/s2mBlobDatasetFields";

import '../DWAS2M.css';

export const DWAS2MPage = ({type}: {type:string}) => {
  const activeTab = useRecoilValue(dwaActiveTab);
  const config = useRecoilValue(dwaConfigSettings);
  const datasetsData = useRecoilValue(datasets);

  useReconcileDatasets(config, datasetsData);

  const isDatasetList = ['logs', 'settings'].every(tab => activeTab !== tab);

  const datasetSQLFields = activeTab === 'managed' ? 
    managedSQLFields : unmanagedSQLFields;

  const datasetBlobFields = activeTab === 'managed' ? 
    managedBlobFields : unmanagedBlobFields;

  const isTypeSQL = type === 'SQL';

  const datasetFields = isTypeSQL ? datasetSQLFields : datasetBlobFields;

  const DWAS2MSettings = isTypeSQL ? SQLSettings : BlobSettings;

  return (
    <>
      <DWAS2MCommandBar />
      <div className='da-dwa'>
        <Container>
          <DWAS2MTabs />
          {activeTab === 'settings' && <DWAS2MSettings />}
          {isDatasetList && (
            <div className='da-dataset-list'>
              <DWAS2MListOptions />
              <DWAS2MGrid
                key={'grid_sql_' + activeTab}
                datasetFields={datasetFields}
                rowRenderer={DWAS2MRowRenderer}
                columnRenderer={DWAS2MColumn}
              />
            </div>
          )}
          <div
            className='da-logs-container'
            style={{ display: activeTab === 'logs' ? 'block' : 'none' }}>
            <JobsTab />
          </div>
        </Container>
        <DWAS2MDatasetDrilldown />
      </div>
    </>
  );
};

export default DWAS2MPage;
