import React from 'react';
// https://reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends React.Component<{errorComponent?: any}, {hasError: boolean}> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		//   logErrorToMyService(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			if (this.props.errorComponent) {
				const ErrorComponent = this.props.errorComponent;
				return <ErrorComponent />
			}
			return <pre>Something went wrong.</pre>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;