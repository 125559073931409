import React, { useCallback, useContext, useState} from 'react';
import { 
	DefaultButton, 
	Dropdown,
	FontIcon, 
	IColumn, 
	Toggle,
} from '@fluentui/react';

import { DWAGridContext } from 'providers/DWAGridContext';

import { IDWAConfigDataset } from 'types/IDWA';

import { 
	getSelectedType,
	getSelectedTypeValues,
	isDatasetValid, 
	isFieldDisabled,
 } from 'components/utils/DWAS2MHelper'

import { initialDeltaWatermarkValues } from 'enums/deltaWatermark'
import { typeOptions } from 'enums/typeOptions';

export const DWAS2MColumn = (dataset: IDWAConfigDataset, _?: number, column?: IColumn) => {
	const {
		enabled,
		modified,
		name,
		status
	} = dataset;

	const {
		updateStatus, 
		updateDataset, 
		setSelectedDataset,
	} = useContext(DWAGridContext);

	const [hover, setHover] = useState(false);

	const onTypeChanged = useCallback((_: any, value: any) => {
		const { extraction_type, load_type } = getSelectedTypeValues(value.key) || {};
		updateDataset(
			name, 
			{ ...initialDeltaWatermarkValues, extraction_type,  load_type, },
		);
	}, [name, updateDataset]);

	const toggleEnabled = useCallback((_: any, checked?: boolean) => {
		if (dataset && typeof checked === 'boolean') {
			updateDataset(name, { enabled: checked })
		}
	}, [dataset, name, updateDataset]);

	const fieldName = dataset[column?.fieldName as keyof IDWAConfigDataset] as string;

	const isDisabled = isFieldDisabled(status, updateStatus.isUpdating);

	const isStatusRemoved = status === 'removed';
	const isStatusUnmanaged = status === 'unmanaged';
	const isStatusChanged = ['changed', 'removed'].some(action => status === action);

	const isValid = isDatasetValid(dataset);

	const dropdownStyles = { 
		root: {
			width: '90%',
		},
		title: { borderColor: !isValid ? 
			'var(--warning)' : 'var(--neutralSecondary)',
		},
	};

	const spanStyles: React.CSSProperties = {
		fontStyle: isStatusChanged ? 'italic' : 'normal',
		fontWeight: isStatusChanged ? 'bold' : 500,
		color: !isStatusRemoved ? 'inherit' : 'var(--warning)',
		cursor: 'pointer',
		...(hover && { textDecoration: 'underline'}),
	};

	const iconStyles: React.CSSProperties = {
		color: !isValid ? 
			'var(--warning)' : 'var(--neutralPrimary)',
		fontSize: '10px',
		marginRight: '8px',
	};

	const buttonStyles: React.CSSProperties = {
		borderColor: `var(--${isDisabled ? 'neutralLighter' : 'themePrimary'})`,
		color: `var(--${isDisabled ? 'neutralTertiary' : 'themePrimary'})`,
	};

	const mouseEvent = {
		onMouseEnter: () => setHover(true),
		onMouseLeave: () => setHover(false),
	};

	switch (column?.key) {
		case 'name':
			return (
				<>
					<span
						{...mouseEvent}
						style={spanStyles}
						onClick={() => setSelectedDataset(dataset)}
					>
						{!(isStatusRemoved || isStatusUnmanaged) && 
							<FontIcon 
								iconName={!isValid ? 'warning' : 'edit'} 
								style={iconStyles}
							/>
						}
						{fieldName} 
					</span>
					{modified ? <span style={{ color: 'var(--themePrimary)' }}>&bull;</span> : ''}
				</>
			);
		case 'type':
			return (
				<Dropdown 
					selectedKey={getSelectedType(dataset)}
					options={typeOptions}
					styles={dropdownStyles}
					onChange={onTypeChanged}
					disabled={isDisabled} 
				/>
			);
		case 'enabled':
			return (
				<Toggle
					checked={enabled}
					onChange={toggleEnabled}
					disabled={isDisabled}
				/>
			);
			
		case 'actions':
			return (
				<DefaultButton
					text='Add to Managed'
					style={buttonStyles}
					onClick={() => updateDataset(name, { status: 'changed' })}
				/>
			);
		default:
			return;
	}
}
