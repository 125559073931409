import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { loadTheme } from '@fluentui/style-utilities';

export const initializeTheme = () => {
	initializeIcons();
	// actual values are inside index.css
	loadTheme({
		palette: {
			themePrimary:         'var(--themePrimary)',
			themeLighterAlt:      'var(--themeLighterAlt)',
			themeLighter:         'var(--themeLighter)',
			themeLight:           'var(--themeLight)',
			themeTertiary:        'var(--themeTertiary)',
			themeSecondary:       'var(--themeSecondary)',
			themeDarkAlt:         'var(--themeDarkAlt)',
			themeDark:            'var(--themeDark)',
			themeDarker:          'var(--themeDarker)',
			neutralLighterAlt:    'var(--neutralLighterAlt)',
			neutralLighter:       'var(--neutralLighter)',
			neutralLight:         'var(--neutralLight)',
			neutralQuaternaryAlt: 'var(--neutralQuaternaryAlt)',
			neutralQuaternary:    'var(--neutralQuaternary)',
			neutralTertiaryAlt:   'var(--neutralTertiaryAlt)',
			neutralTertiary:      'var(--neutralTertiary)',
			neutralSecondary:     'var(--neutralSecondary)',
			neutralPrimaryAlt:    'var(--neutralPrimaryAlt)',
			neutralPrimary:       'var(--neutralPrimary)',
			neutralDark:          'var(--neutralDark)',
			black:                'var(--black)',
			white:                'var(--white)'
		}
	});
};

