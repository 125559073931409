import { IDropdownOption } from '@fluentui/react';

export const deltaWatermarkTypeOptions: IDropdownOption[] = [
	{key: 'date', text:'Date'}, 
	{key: 'number', text:'Number'}, 
];

export const initialDeltaWatermarkValues = {
	delta_watermark_column: '',
    delta_watermark_offset_type: 'date',
    delta_watermark_offset: 0,
    delete_delta_watermark_column: '',
    delete_delta_watermark_offset_type: 'date',
    delete_delta_watermark_offset: 0,
};