import React from 'react';
import { useRecoilValue } from 'recoil';
import { environments } from "../../../recoil/remote/environments";
import { solution } from "../../../recoil/remote/solution";
import { Container } from "../../common/Container";
import { EnvironmentCard } from "./EnvironmentCard";
import './ManagePage.css';

export const ManagePage: React.FC = () => {
	const solutionData     = useRecoilValue(solution);
	const environmentsData = useRecoilValue(environments);

	if (solutionData) {
		return (
			<div className='da-manage-page'>
				<Container>
					<header>
						<h2>Manage {solutionData.name}</h2>
						{/* <CommandButton iconProps={{ iconName: 'Add' }} text='Create New Environment' /> */}
					</header>
					<div className='da-environments'>
						{environmentsData.map(environment => 
							<EnvironmentCard key={environment.id} solutionId={solutionData.id} data={environment} />
						)}
					</div>
				</Container>
			</div>
		);
	}

	// todo: add fallback
	return (
		<div />
	);
};

export default ManagePage;