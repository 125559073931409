import { atomFamily, SerializableParam, selector, waitForAll } from "recoil";
import { IDatasource } from "../../types/IDatasource";
import { selectedEnvironmentId } from "../atoms/selectedEnvironmentId";
import { selectedSolutionId } from "../atoms/selectedSolutionId";
import { fetchRemoteEffect } from "../effects/fetchRemoteEffect";
import { apiEndpoint } from "./apiEndpoint";
import { dwa } from "./dwa";
import { noopPromise } from "./noopPromise";

export type IDatasourceParams = SerializableParam & {
	endpoint: string;
	solutionId: string;
	environmentId: string;
	datasourceId?: string;
}

export const datasourceAtom = atomFamily<IDatasource | null, IDatasourceParams>({
	key: 'datasource',
	default: noopPromise(null),
	effects_UNSTABLE:[
		fetchRemoteEffect<IDatasourceParams>(
			({ endpoint, solutionId, environmentId, datasourceId }) => 
				`${endpoint}/${solutionId}/environments/${environmentId}/datasources/${datasourceId}`
		)
		
	]
})

export const datasource = selector<IDatasource | null>({
	key: 'datasource/proxy',
	get: async ({ get }) => {
		const endpoint = get(apiEndpoint);
		const solutionId = get(selectedSolutionId);
		const environmentId = get(selectedEnvironmentId);
		const dwaData = get(dwa);

		if (endpoint && environmentId && dwaData?.de_config.datasource_id) {
			return get(waitForAll([
				datasourceAtom({ endpoint, solutionId, environmentId, datasourceId: dwaData?.de_config.datasource_id })
			]))[0];
		}

		return null;
	}
});