import { authProvider } from '../../providers/AuthProvider';

export async function standardHeaders() {
	const token = await authProvider.getIdToken();
	const headers = new Headers();
	headers.set('Content-Type', 'application/json');
	if (token) {
		headers.set('Authorization', `Bearer ${token.idToken.rawIdToken}`);
	}
	return headers;
}